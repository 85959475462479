const last = arr => [].concat(arr).pop();

const identity = d => d;

const findLastIndex = (arr, fn) => (arr || []).map(d => fn(d)).lastIndexOf(true);

const cloneDeep = obj => {
  if (Array.isArray(obj)) {
    return obj.map(d => cloneDeep(d))
  } else if (obj !== null && obj !== undefined && obj.constructor.name === 'Object') {
    return Object.keys(obj).reduce((t, key) => {
      const v = obj[key]
      t[key] = cloneDeep(v)
      return t;
    }, {})
  } else {
    return obj;
  }
}

const merge = (obj1, obj2) => {
  return {...obj1, ...obj2}
}

const uniqBy = (arr, fn = identity) => arr.filter((e, index) => arr.findIndex((d) => fn(e) === fn(d)) === index);

const get = (obj, path, defaultValue = undefined) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === null || result === obj ? defaultValue : result;
};

const sum = arr => (arr || []).reduce((t, d) => t + (d || 0), 0);
const max = arr => Math.max(...arr)
const min = arr => Math.min(...arr)

const sortBy = function(arr, iterator) {
  const isString = typeof iterator === 'string';
  return arr.sort(function(x, y) {
      const gt = isString ? x[iterator] > y[iterator] : iterator(x) > iterator(y);
      const lt = isString ? x[iterator] < y[iterator] : iterator(x) < iterator(y);
      return gt ? 1 : (lt ? -1 : 0)
  });
};

const filter = (arr, fn) => {
  return (arr || []).filter(fn)
}

const range = (stop, step = 1) => {
  const start = 0;
  return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
}

const uniq = arr => [...new Set(arr)];

const find = (arr, fn) => {
  return (arr || []).find(fn)
}

function pick(object, keys) {
  return keys.reduce((obj, key) => {
     if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
     }
     return obj;
   }, {});
}

const differenceBy = (arr1, arr2, fn = identity) => {
  arr1 = arr1 || [];
  arr2 = arr2 || [];
  const res = [];
  for (var i = 0; i < arr1.length; i++) {
    const v1 = fn(arr1[i])
    let found = false;
    for (var j = 0; j < arr2.length; j++) {
      if (found) continue;
      const v2 = fn(arr2[j])
      if (v1 === v2) {
        found = true;
      }
    }   
    if (!found) res.push(arr1[i])
  }
  return res;
}

const each = (collection, iterator) => {
    if (Array.isArray(collection)) {
        for (var i = 0; i < collection.length; i++)
            iterator(collection[i], i, collection);
    } else if (collection instanceof Object) {
        for (var key in collection)
            iterator(collection[key], key, collection);
    } else if (collection === null) {
        return collection;
    }
};


function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

const chunk = (input = [], size) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export default {
  max,
  min,
  chunk,
  debounce,
  each,
  uniq,
  uniqBy,
  sortBy,
  filter,
  merge,
  differenceBy,
  get,
  sum,
  pick,
  find,
  findLastIndex,
  cloneDeep,
  last,
  identity,
  range,
}