import { Fragment, Component } from 'preact';
import { Helmet } from 'react-helmet';
import Landing from './../../components/home/landing-companies';

const image = 'https://i.stockideashq.com/money.png';
const title = 'Get Stock Ideas - Stockideas HQ';
const content = 'Get stock ideas from traders';

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as authActions from './../../actions/auth';

@connect(reduce, authActions)
export default class HomePage extends Component {
  render() {
    const { user } = this.props;

    return (
      <>
        <Helmet>
          <title>{ title }</title>
          <meta name='description' content={ content } />
          <meta name='keywords' content='stock trading ideas, day trade, stock signals, stock screener, technical analysis, saas stocks' />
          <meta property='og:image' content={ image } />
          <meta property='og:title' content={ title } />
          <meta property='og:description' content={ content } />
          <meta property='twitter:image' content={ image } />
          <meta property='twitter:title' content={ title } />
          <meta property='twitter:description' content={ content } />
          <link rel='canonical' href='/' />
        </Helmet>
        <Landing user={user} />
      </>
    );
  }
}
