import { Component } from 'preact';
import LazyLoad from 'react-lazyload';
// import loadable from '@loadable/component';
// const FaTelegram = loadable(() => import('./../svg-components/telegram');
// const FaFacebook = loadable(() => import('./../svg-components/facebook');
// const FaWhatsapp = loadable(() => import('./../svg-components/whatsapp');
// const FaTwitter = loadable(() => import('./../svg-components/twitter');
// const FaInstagram = loadable(() => import('./../svg-components/instagram');
import FaTelegram from './../svg-components/telegram';
import FaFacebook from './../svg-components/facebook';
import FaWhatsapp from './../svg-components/whatsapp';
import FaTwitter from './../svg-components/twitter';
import FaInstagram from './../svg-components/instagram';

import { Link } from 'preact-router/match';
import style from './style.css';

export default class Footer extends Component {
  render() {
    const { theme ='light' } = this.props;

    return (
      <footer>
      <div class={ style['si-footer'] + ` row no-gutters` }>
        <div class='col-md-3 col-sm-12 col-xs-12'>
          <LazyLoad><div>
            <div class={`mb-1 font-14 theme-black-${theme}`}>Follow us:</div>
            <a rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/stockideashq'>
              <FaFacebook className={ style['si-footer-facebook'] + ` theme-social-${theme}` } />
            </a>
            <a rel='noopener noreferrer' target='_blank' href='https://twitter.com/stockideashq'>
              <FaTwitter className={ style['si-footer-twitter'] + ` theme-social-${theme}` } />
            </a>
            <a rel='noopener noreferrer' target='_blank' href='https://www.instagram.com/stockideashq/'>
              <FaInstagram className={ style['si-footer-instagram'] + ` theme-social-${theme}` } />
            </a>
            <a rel='noopener noreferrer' target='_blank' href='https://t.me/stockideashq'>
              <FaTelegram className={ style['si-footer-telegram'] + ` theme-social-${theme}` } />
            </a>
            <a rel='noopener noreferrer' target='_blank' href='https://chat.whatsapp.com/EXI29U7nVn63L7CZuYGxtp'>
              <FaWhatsapp className={ style['si-footer-whatsapp'] + ` theme-social-${theme}` } />
            </a>
          </div>
          </LazyLoad>
        </div>
        <div class='col-md-9 col-sm-12 col-xs-12' />
        <div class='col-md-12 col-sm-12 col-xs-12'>
          
        </div>
        <div class='col-md-3 col-sm-12 col-xs-12' />
        <div class='col-md-12 col-sm-12 col-xs-12 center'>
          <div class='font-12'>
            <div>
              <Link class={`m-1 theme-blue-${theme}`} href='/privacy'>Privacy</Link><span>•</span>
              <Link class={`m-1 theme-blue-${theme}`} href='/disclaimer'>Disclaimer</Link><span>•</span>
              <Link class={`m-1 theme-blue-${theme}`} href='/terms'>Terms</Link><span>•</span>
              <Link class={`m-1 theme-blue-${theme}`} href='/about'>About Us</Link>
            </div>
            <div class={`m-1 theme-black-${theme}`}>@ 2022 stockideashq.com. All rights reserved.</div>
            <div>For more information please contact&nbsp; <a rel='noopener noreferrer' target='_blank' href='mailto:support@stockideashq.com' class={`theme-blue-${theme}`}>support@stockideashq.com</a></div>
          </div>
          <br />
        </div>
      </div>
      </footer>
    );
  }
}
