import { Component } from 'preact';
import { Router } from 'preact-router';
import loadable from '@loadable/component';
import { useEffect } from 'preact/hooks';
import ReactGA from 'react-ga';

import Home from './pages/home';
import Screener from './pages/screener';
import Signals from './pages/signals';
const Patterns = loadable(() => import('./pages/patterns'));
const Pattern = loadable(() => import('./pages/patterns/pattern'));
import Sectors from './pages/sectors';
import Sector from './pages/sectors/sector';
const StockPickingGame = loadable(() => import('./pages/pickinggame'));
const CreatePostPage = loadable(() => import('./pages/posts/create'));
const Thread = loadable(() => import('./pages/posts/thread'));
import Ticker from './pages/ticker';
const Article = loadable(() => import('./pages/article'));
const About  = loadable(() => import('./pages/system/about'));
const Thankyou  = loadable(() => import('./pages/system/thankyou'));
const CCPageIncomeStatements  = loadable(() => import('./pages/system/cc-page-income-statements'));
const CCMinMaxDrawdown  = loadable(() => import('./pages/system/cc-min-max-drawdown'));
const VerifyEmail  = loadable(() => import('./pages/system/verifyemail'));
const Reset  = loadable(() => import('./pages/system/reset'));
const ResetPassword  = loadable(() => import('./pages/system/reset-password'));
const RequestReset  = loadable(() => import('./pages/system/request-reset'));
const BecomeWriter  = loadable(() => import('./pages/system/become-a-writer'));
const EditPostPage  = loadable(() => import('./pages/posts/edit'));
const StockPickingGameRules  = loadable(() => import('./pages/pickinggame/rules'));
const Login  = loadable(() => import('./pages/login'));
const Privacy  = loadable(() => import('./pages/privacy'));
const Disclaimer  = loadable(() => import('./pages/disclaimer'));
const Terms  = loadable(() => import('./pages/terms'));
const Register  = loadable(() => import('./pages/register'));
const Me  = loadable(() => import('./pages/me'));
const Writer  = loadable(() => import('./pages/writers'));
const InsiderByPerson  = loadable(() => import('./pages/insiders/person'));
const StockPicks  = loadable(() => import('./pages/pickinggame/mine'));

class RouterMain extends Component {
  handleRoute = async e => {
    ReactGA.pageview(e.url);
  };

  render() {
    return (
      <Router onChange={this.handleRoute}>
        <Ticker path="/" />
        <Ticker path="/stocks/:ticker" />
        <Ticker path="/stocks-admin/:ticker" />
        <Article path="/articles/:articleId" />
        <Login path="/login" />
        <CreatePostPage path="/createpost" />
        <EditPostPage path="/posts/:postKey/:slug/edit/:postId" />
        <Me path="/me" />
        <Writer path="/users/:username" />
        <Writer path="/writers/:username" />
        <Register path="/register" />
        <Thankyou path="/thankyou" />
        <CCPageIncomeStatements path="/cc/income-statements" />
        <CCMinMaxDrawdown path="/cc/stocks/:ticker/min-max" />
        <Thread path="/posts/:postKey/:slug" />
        <Reset path="/thankyou-reset" />
        <VerifyEmail path="/verifyemail/:emailcode" />
        <ResetPassword path="/resetcode/:resetcode" />
        <RequestReset path="/request-reset" />
        <BecomeWriter path="/become-a-writer" />
        <Screener path="/screener" />
        <Terms path="/terms" />
        <Signals path="/signals" />
        <Patterns path="/patterns" />
        <Pattern path="/patterns/:pattern" />
        <InsiderByPerson path="/stocks/:ticker/insiders/:person" />
        <StockPickingGame path="/stock-picking-game" />
        <Sectors path="/sectors" />
        <Sector path="/sectors/:sector" />
        <Privacy path="/privacy" />
        <Disclaimer path="/disclaimer" />
        <About path="/about" />
        <StockPicks path="/stock-picking-game/mine" />
        <StockPickingGameRules path="/stock-picking-game/rules-and-how-to" />
        <Home default />
      </Router>
    );
  }
}

export default RouterMain;
