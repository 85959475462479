import { Component } from 'preact';

class Medals extends Component {
  render() {
    const { medals } = this.props;
    if ( !medals ) {
      return null;
    }

    return (
      <>
        {medals.gold ? <span>{medals.gold}<span class='gold'>&nbsp;◉&nbsp;</span></span> : null}
        {medals.silver ? <span>{medals.silver}<span class={`silver`}>&nbsp;◉&nbsp;</span></span> : null}
        {medals.copper ? <span>{medals.copper}<span class={`copper`}>&nbsp;◉&nbsp;</span></span> : null}
      </>
    );
  }
}

export default Medals;
