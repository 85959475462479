import { Component } from 'preact';

class FaGlassdoor extends Component {
  render() {
    const { color = 'gray', className } = this.props;
    return <svg fill={ color } class={ className } viewBox='100 140 72 72' style={ { height: '1em', width: '1em' } }><path d='M149.6 201.82h-40.27c0 5.6 4.5 10.13 10.07 10.13h30.2c5.56 0 10.06-4.54 10.06-10.13V160.2c0-.2-.16-.38-.36-.38h-9.34c-.2 0-.36.17-.36.37v41.62zm0-60.82c5.56 0 10.06 4.54 10.06 10.14H119.4v41.62c0 .2-.17.37-.37.37h-9.33c-.2 0-.37-.17-.37-.37v-41.62c0-5.6 4.5-10.14 10.07-10.14h30.2z' /></svg>;
  }
}

export default FaGlassdoor;
