import APIUtils from './../../utils/APIUtils';
import _ from './../../utils/lodash';
import { toast } from 'react-toastify';
import {
  // calculatePriceAndPatterns,
  reduceByWeek,
  reduceByMonth,
  removeGapsFromTheEnd,
} from './../../utils/helper';

const timeoutHelper = (() => {
  let timeout;

  return {
    deduplicate: (cb) => {
      if (!timeout) {
        timeout = setTimeout(() => {
          clearTimeout(timeout)
          timeout = null
        }, 5000)
        cb();
      }
    }
  }
})();

export const getTickerInsiderByPerson = ( ticker, person ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/insiders/tickers/${ticker}/person/${person}` ).then( ( d ) => {
  dispatch( {
    type: 'GET_TICKER_INSIDER_PERSON_ENTRY',
    payload: { insiderInfo: d },
  } );
} ).catch( err => {
  console.log(err);
} );

export const getTicker = ( ticker ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/tickers/${ ticker.toLowerCase() }/info` ).then( profile => {
  profile.income_statement_fm = profile.income_statement_fm || {}
  profile.income_statement_fm.data = removeGapsFromTheEnd(profile.income_statement_fm.data || []);
  profile.cashflow_statement_combined = profile.cashflow_statement_combined || {}
  profile.cashflow_statement_combined.data = removeGapsFromTheEnd(profile.cashflow_statement_combined.data || []);
  profile.balance_sheet_fm = profile.balance_sheet_fm || {}
  profile.balance_sheet_fm.data = removeGapsFromTheEnd(profile.balance_sheet_fm.data || []);

  dispatch( {
    type: 'GET_TICKER',
    payload: { profile },
  } );
} ).catch( err => {
  console.log( err );
  timeoutHelper.deduplicate(() => {
    toast.error( 'Error getting stock information. Please try again later.' );
  })
} );

export const getTickerMain = ( ticker ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/tickers/${ ticker.toLowerCase() }/profile-main`, { retry: 1 } ).then( main => {
  dispatch( {
    type: 'GET_TICKER',
    payload: { main },
  } );
} ).catch( err => {
  console.log( err );
  timeoutHelper.deduplicate(() => {
    toast.error( 'Error getting stock information. Please try again later.' );
  })
} );

export const getTickerRest = ( ticker ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/tickers/${ ticker.toLowerCase() }/profile-rest`, { retry: 1 } ).then( rest => {
  dispatch( {
    type: 'GET_TICKER',
    payload: { rest },
  } );
} ).catch( err => {
  console.log( err );
  timeoutHelper.deduplicate(() => {
    toast.error( 'Error getting stock information. Please try again later.' );
  })
} );

export const getTickerNumbers = ( ticker ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/tickers/${ ticker.toLowerCase() }/numbers`, { retry: 1 } ).then( numbers => {
  dispatch( {
    type: 'GET_TICKER',
    payload: { numbers },
  } );
} ).catch( err => {
  console.log( err );
  timeoutHelper.deduplicate(() => {
    toast.error( 'Error getting stock information. Please try again later.' );
  })
} );

export const getTickerPrice = ( ticker, shift = 0 ) => ( dispatch, getState ) => {
  const user = getState().user;
  let url = `/tickers/${ ticker.toLowerCase() }/price?shift=${ shift }`
  
  APIUtils.get( getState().token, url, { retry: 1 } ).then( d => {
    // const dailypatterns = getState().profile.dailypatterns || {};
    // const weeklypatterns = getState().profile.weeklypatterns || {};
    // const monthlypatterns = getState().profile.monthlypatterns || {};

    const data = d.data.map( ( d, i ) => {
      const date = new Date( d[ 0 ] );
      date.setTime( date.getTime() + date.getTimezoneOffset() * 60 * 1000 );
      return {
        date,
        dateshort: d[0],
        open: d[ 1 ],
        high: d[ 2 ],
        low: d[ 3 ],
        close: d[ 4 ],
        volume: d[ 5 ],
      };
    }).reverse();

    // const { priceAndPatterns: dailypriceNew } = calculatePriceAndPatterns( d.data, dailypatterns );
    // const priceweekly = {
    //   patterns: [],
    //   data: reduceByWeek( data )
    // }
    // const priceweekly = _.cloneDeep( d );
    // priceweekly.patterns = [];
    // priceweekly.data = reduceByWeek( priceweekly.data );
    // const pricemonthly = {
    //   patterns: [],
    //   data: reduceByMonth( data )
    // }
    // const pricemonthly = _.cloneDeep( d );
    // pricemonthly.patterns = [];
    // pricemonthly.data = reduceByMonth( pricemonthly.data );
    // const { priceAndPatterns: weeklypriceNew } = calculatePriceAndPatterns( priceweekly.data, weeklypatterns );
    // const { priceAndPatterns: monthlypriceNew } = calculatePriceAndPatterns( pricemonthly.data, monthlypatterns );
    dispatch( {
      type: 'GET_TICKER_PRICES',
      payload: {
        daily: data, weekly: reduceByWeek( data ), monthly: reduceByMonth( data ), latestprice: null,
      },
    } );
  } ).catch( err => {
    console.log( err );
    timeoutHelper.deduplicate(() => {
      toast.error( 'Error getting stock information. Please try again later.' );
    })
  } );
}

export function subscribeTickerPriceTopics( ticker ) {
  return {
    type: 'SUBSCRIBE_TOPICS',
    payload: { chart: ticker },
  };
}

export const recordSearchTicker = ( ticker ) => ( dispatch, getState ) => {
  APIUtils.post( getState().token, `/tickers/${ticker}/search`, { } );
};

export const createAlert = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, '/alerts', { ticker } ).then( ( info ) => {
    dispatch( {
      type: 'CREATE_ALERT_SUCCESS',
      payload: ticker,
    } );
    toast.info( 'Successfully subscribed to this ticker.' );
  } ).catch( err => {
    dispatch( {
      type: 'CREATE_ALERT_ERROR',
      payload: err.message,
    } );
  } );
};

export const removeAlert = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.del( getState().token, `/alerts/${ ticker }` ).then( ( info ) => {
    dispatch( {
      type: 'REMOVE_ALERT_SUCCESS',
      payload: ticker,
    } );
    toast.info( 'Successfully unsubscribed from this ticker.' );
  } ).catch( err => {
    dispatch( {
      type: 'REMOVE_ALERT_ERROR',
      payload: err.message,
    } );
  } );
};
