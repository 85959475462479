import { Component } from 'preact';
import { Link } from 'preact-router/match';
import { route } from 'preact-router';
import style from './say-something.css';

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as authActions from './../../actions/auth';

@connect(reduce, authActions)
export default class SaySomething extends Component {
  render() {
    const { user } = this.props;

    return (
      <Link onClick={() => route(user && user._id ? '/createpost' : '/login')}>
        <div class={`${style['si-post-button']} btn btn-round btn-warning pointer`}>{user ? 'Post' : 'Login / Post'}</div>
      </Link>
    );
  }
}
