import { toast } from 'react-toastify';
import APIUtils from './../../utils/APIUtils';

export const getWriter = ( username ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/writers/by/${username}` ).then( d => dispatch( {
  type: 'WRITER_GET',
  payload: d,
} ) ).catch( err => {
  console.log( err );
} );

export const getWriters = () => ( dispatch, getState ) => APIUtils.get( getState().token, `/writers` ).then( d => dispatch( {
  type: 'WRITERS_GET',
  payload: d,
} ) ).catch( err => {
  console.log( err );
} );

export const getInterestingCompanies = () => ( dispatch, getState ) => APIUtils.get( getState().token, `/interesting-companies` ).then( interesting_companies => dispatch( {
  type: 'INTERESTING_COMPANIES_GET',
  payload: { interesting_companies },
} ) ).catch( err => {
  console.log( err );
} );

export const getWriterPosts = ( username, skip = 0 ) => ( dispatch, getState ) => {
  const currentPosts = getState().writerPosts || []
  const currentWriterUsername = getState().writerUsername
  APIUtils.get( getState().token, `/writers/posts/by/${username}?skip=${skip}` ).then( posts => {
    // if (currentWriterUsername === username) {
      dispatch( {
        type: 'WRITER_POSTS_GET',
        payload: { writerPosts: currentPosts.concat(posts).filter(d => d.username === username), writerUsername: username },
      } )
    // } else {
    //   dispatch( {
    //     type: 'WRITER_POSTS_GET',
    //     payload: { writerPosts: posts, writerUsername: username },
    //   } )
    // }
  } ).catch( err => {
    console.log( err );
  } );
}

export const getWriterInventory = ( username ) => ( dispatch, getState ) => {
  APIUtils.get( getState().token, `/users/${username}/inventory` ).then( ( writerInventory ) => {
    dispatch( {
      type: 'TARGET_USER_INVENTORY_GET',
      payload: { writerInventory },
    } );
  } ).catch( err => {
    console.log( err );
  } );
};

export const followWriter = ( username ) => ( dispatch, getState ) => APIUtils.post( getState().token, `/writers/${username}/follow`, {} ).then( d => { 
  toast.success( 'You have successfully followed this creator.' );
  return dispatch( {
    type: 'WRITER_FOLLOW_SUCCESS',
  } ) 
} ).catch( err => {
  if (err.message === 'error_already_followed') {
    toast.error( 'You have already followed this creator.' );
  }
  console.log( err );
} );

export const unfollowWriter = ( username ) => ( dispatch, getState ) => APIUtils.post( getState().token, `/writers/${username}/unfollow`, {} ).then( d => { 
  toast.success( 'You have successfully unfollowed this creator.' );
  return dispatch( {
    type: 'WRITER_UNFOLLOW_SUCCESS',
  } ) 
} ).catch( err => {
  if (err.message === 'error_not_following') {
    toast.error( 'You are not following this creator.' );
  }
  console.log( err );
} );
