/* eslint-disable max-len */

import { Component } from 'preact';
import dayjs from 'dayjs';
import { Link } from 'preact-router/match';
import loadable from '@loadable/component';
import { route } from 'preact-router';
import LazyLoad from 'react-lazyload';
// const AdSense = loadable(() => import('react-adsense'));
import AdSense from 'react-adsense';
import store from 'store';
import FaHeart from './../svg-components/fa-heart';
import _ from './../../utils/lodash';
const Analysts = loadable(() => import('./analysts'));
const AnalystTrends = loadable(() => import('./analysts-trends'));
const PriceLoader = loadable(() => import('./price-loader'));
const IncomeTable = loadable(() => import('./income-table'));
const Expense = loadable(() => import('./expense-chart'));
const Estimates = loadable(() => import('./estimates'));
const CashflowChart = loadable(() => import('./cashflow-chart'));
// const CapitalExpenditure = loadable(() => import('./capex'));
const IncomeChart = loadable(() => import('./income-chart'));
const CashFlows = loadable(() => import('./cashflow-table'));
const Debts = loadable(() => import('./debts'));
const BalanceSheet = loadable(() => import('./balance-table'));
import Company from './company';
const Financials = loadable(() => import('./Financials'));
import Insiders from './../insider';
import Roster from './../roster';
// import ImageWrapper from './../System/ImageWrapper';
import PriceChange from './../pricechange';
const Posts = loadable(() => import('./../post/posts'));
const News = loadable(() => import('./../news'));
const AdminPanel = loadable(() => import('./../admin/admin-panel'));
const MaintainerPanel = loadable(() => import('./../admin/maintainer-panel'));
import TickerBox from './../tickerbox';
// const Chart = loadable(() => import('./../chart'))
const Chart = loadable(() => import('./../chart'))
const ChartSmall = loadable(() => import('./../chart/small'))
const GenericBar = loadable(() => import('./../genericbar'))
import style from './ticker.css';
// import GradientHide from './../System/GradientHide';

// you will be refreshing prices again when you go to another batch
const DAYS_PER_BATCH = 2500;

const isAdmin = ( user ) => user && user.roles && user.roles.indexOf( 'admin' ) > -1;
const isMaintainer = ( user, ticker ) => {
  return user && user.roles && user.roles.indexOf( 'maintainer' ) > -1 && (user.maintainer_tickers || []).indexOf(ticker) > -1;
};

const deDupByYearMonth = (arr) => {
  return _.uniqBy(arr, d => `${dayjs(d.datestr).year()}${dayjs(d.datestr).month()}`)
};

export default class Ticker extends Component {
  constructor( props ) {
    super( props );

    // normal methods
    this.toggleCreatePost = this.toggleCreatePost.bind( this );
    this.proceed = this.proceed.bind( this );
    this.onSuggestionSelected = this.onSuggestionSelected.bind( this );
    this.onClickAlert = this.onClickAlert.bind( this );
    this.fetchTicker = this.fetchTicker.bind( this );
    this.state = {
      isViewAdminPanel: store.get( 'isViewAdminPanel' ),
      isViewAdminMiscPanel: store.get( 'isViewAdminMiscPanel' ),
      // isLargeChart: store.get( 'isLargeChart' ),
      tickerStr: this.props.match && this.props.match.params && this.props.match.params.ticker ? this.props.match.params.ticker.toUpperCase() : 'AAPL',
      batches: [ 0 ],
      // pattern: props.location.query.p || "gravestone-doji",
      chartType: 'daily',
      shift: 0,
    };
  }

  proceed( tickerStr ) {
    this.fetchTicker( tickerStr, 0 );
  }

  toggleCreatePost() {
    const { isCreating } = this.state;
    this.setState( { isCreating: !isCreating } );
  }

  onSuggestionSelected( { suggestionValue } ) {
    this.fetchTicker( suggestionValue, 0 );
  }

  fetchTicker( str, shift ) {
    if ( !str ) {
      return;
    }
    const ticker = str.toLowerCase() || 'aapl';
    route( `/stocks/${ ticker }` );
    this.props.getTicker( ticker );
    this.setState( { tickerStr: ticker.toUpperCase(), shift: 0, batches: [ 0 ] } );
  }

  onClickAlert() {
    const { profile, user, history } = this.props;
    if ( !profile || !profile.ticker ) {
      return null;
    }
    const ticker = profile.ticker;
    if ( !user || !user._id ) {
      route('/login')
      return;
    }
    const favorites = user && user.tickers || [];
    if ( favorites.indexOf( ticker.toUpperCase() ) > -1 ) {
      this.props.removeAlert( ticker.toUpperCase() );
      // this.props.unsubscribeTickerTopics( [ ticker.toUpperCase() ] );
    } else {
      this.props.createAlert( ticker.toUpperCase() );
      // this.props.subscribeTickerTopics( [ ticker.toUpperCase() ] );
    }
  }

  render() {
    const {
      intradayPrice,
      dailyprice,
      weeklyprice = [],
      monthlyprice,
      latestprice,
      profile = {},
      user,
      tickerposts: posts,
      windowWidth,
      inventory,
      items,
      theme = 'light',
      ticker: routeTicker
    } = this.props;
    const ticker = ( profile && profile.ticker ) || '';
    const title = profile.ticker ? `${ profile.ticker } - ${ profile.name } Stock ideas` : `${(routeTicker || '').toUpperCase()} - Stock ideas`;
    const favorites = ( user && user.tickers ) || [];
    const {
      shift,
      chartType,
      tickerStr,
      pattern,
      isFocus,
      isViewAdminPanel,
      isViewAdminMiscPanel,
      isLargeChart,
    } = this.state;
    const lastCandle = _.last( dailyprice );
    const dailypriceAdj = lastCandle && latestprice && latestprice.dateshort !== lastCandle.dateshort ? dailyprice.concat( [ latestprice ] ) : dailyprice;
    const heartStr = ticker && favorites.indexOf( ticker.toUpperCase() ) > -1 ? 'In Favorite' : 'Favorite';
    const heartClass = ticker && favorites.indexOf( ticker.toUpperCase() ) > -1 ? 'float-left btn btn-warning' : `float-left btn btn-theme-${theme} gray-border`;
    const chartPrice = ( dailypriceAdj );
    const capex = _.get(profile, 'cashflow_statement_combined.data', []).map(d => ({
      datestr: d.reportDate,
      value: -d.ce,
    })).slice(-16)
    // const inventoryTurnover = _.uniqBy(_.get(profile, 'numbers.ratios_fm', [].filter(d => d.id)), d => dayjs(d.ts).format('YYYYMM')).map(d => ({
    //   datestr: dayjs(d.ts).format('YYYYMM'),
    //   value: d.it,
    // })).slice(-16)
    // const daysPayableOutstanding = _.uniqBy(_.get(profile, 'numbers.ratios_fm', []).filter(d => d.dpo), d => dayjs(d.ts).format('YYYYMM')).map(d => ({
    //   datestr: dayjs(d.ts).format('YYYYMM'),
    //   value: d.dpo,
    // })).slice(-16)
    // const daysSalesOutstanding = _.uniqBy(_.get(profile, 'numbers.ratios_fm', []).filter(d => d.dso), d => dayjs(d.ts).format('YYYYMM')).map(d => ({
    //   datestr: dayjs(d.ts).format('YYYYMM'),
    //   value: d.dso,
    // })).slice(-16)

    return (
      <>
        {!process.env.PREACT_APP_MOBILE_BUILD ? <div class='row no-gutters sticky w-50 bg-white' style={{ zIndex: 1 }}>
          <div class='mobile-hide' style={ { whiteSpace: 'nowrap' } }>
            <button class={ heartClass } onClick={ this.onClickAlert } ><FaHeart color='darkred' />&nbsp;{heartStr}</button>
            <div class='float-left p-2'>
              <h1 class={ `inline theme-black-${ theme } font-12` }>{title}&nbsp;&nbsp;</h1>
              {latestprice ? <PriceChange close={ latestprice.close } prevClose={ latestprice.prev_close } theme={ theme } /> : null}
            </div>
          </div>
        </div> : null}
        {!process.env.PREACT_APP_MOBILE_BUILD ? <div class='row no-gutters p-1 mb-1'>
          <div style={{ position: 'fixed', top: 10, zIndex: 999999, width: 300, left: 350 }} >
            <TickerBox
              // theme={ theme }
              // value={ tickerStr }
              onSuggestionSelected={ this.onSuggestionSelected }
              // onSelect={ this.onChangeTicker }
              // onUnfocus={ this.onUnfocus }
              // onFocus={ this.onFocus }
              // onKeyDown={ this.onKeyDown }
            />
          </div>
        </div> : null}
        {isViewAdminPanel && isAdmin( user ) ? <AdminPanel key={ `admin-${tickerStr}` } ticker={ profile && profile.ticker } pattern={ pattern } meta={ profile && profile.meta } misc_data={profile && profile.misc_data} theme={theme} url={profile.url} /> : null}
        {profile && profile.ticker && isViewAdminPanel && isViewAdminMiscPanel && (isAdmin( user ) || isMaintainer( user, profile && profile.ticker )) ? <MaintainerPanel key={ `maintainer-${profile.ticker}` } ticker={profile && profile.ticker} misc_data={profile && profile.misc_data} theme={theme} /> : null}
        {/* {items && items.length ? <Rewards items={ items } col={ 1 } /> : null} */}
        <div class='row no-gutters'>
          {!process.env.PREACT_APP_MOBILE_BUILD ? <div class='col-md-8 p-1 mobile-hide'>
            {profile && chartPrice && chartPrice.length ? <Chart
              theme={ theme }
              isAdmin={ isAdmin( user ) }
              latestprice={ latestprice }
              dailyprice={ dailyprice }
              weeklyprice={ weeklyprice }
              monthlyprice={ monthlyprice }
              intradayPrice={ intradayPrice }
              ticker={ profile.ticker }
            /> : <div class='min-height-400' />}
            <div class={ `p-2 round-corner-10 theme-sub-${ theme }` } style={{minHeight: 440, width: '100%'}}>
              <Financials isLoggedin={user && user._id} profile={ profile } theme={ theme } />
            </div>
            <div class='p-1'>
              <span class={ `bold` }>Latest News:</span>
            </div>
            <News profile={ profile } theme={ theme } />
          </div> : null}
          <div class='col-md-4'>
            {profile && isAdmin( user ) && ( !profile.img_url || profile.img_url.indexOf( 'imgur' ) === -1 ) ? <div>{profile && profile.img_url}</div> : null}
            {profile && isAdmin( user ) && !profile.cik ? <div>No cik....</div> : null}
            <div class='min-height-250 mb-2'>
              <Company profile={ profile } theme={ theme } />
            </div>
            <div class={ `no-gutters si-component-wrapper mb-2` }>
              {/*<span class={ `bold` }>Earnings Estimates:</span>
              <hr class='m-1' />*/}
              <Estimates theme={theme} profile={profile} />
            </div>
            <div class={ `no-gutters si-component-wrapper mb-2` }>
              {/*<span class={ `bold` }>Analyst Consensus:</span>
              <hr class='m-1' />*/}
              <Analysts profile={profile} theme={theme} />
            </div>
            <LazyLoad><AdSense.Google
              client='ca-pub-2231222372493371'
              slot='3790812214'
              style={{ display: 'block' }}
              format='fluid'
              responsive='true'
            /></LazyLoad>
          </div>
          {process.env.PREACT_APP_MOBILE_BUILD ? <div class='col-md-12 browser-hide'>
            <div class={ `p-2 round-corner-10 theme-sub-${ theme }` } style={{minHeight: 2050, width: '100%'}}>
              <Financials isLoggedin={user && user._id} profile={ profile } theme={ theme } />
            </div>
            <LazyLoad><AdSense.Google
              client='ca-pub-2231222372493371'
              slot='3790812214'
              style={{ display: 'block' }}
              format='fluid'
              responsive='true'
            /></LazyLoad>
          </div> : null}
          <div class='col-md-12'>
            <div class='row no-gutters'>
              <div class='col-md-8'>
              <div class={ `p-2 round-corner-10 theme-sub-${ theme } mt-1` } style={{ minHeight: 725 }}>
                <IncomeTable isSmall={process.env.PREACT_APP_MOBILE_BUILD} theme={theme} profile={profile} count={4 * 30} prop={'income_statement_combined'} />
              </div>
              </div>
              <div class='col-md-4'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  {/*<span class={ `bold` }>Margins and Revenue:</span>
                  <hr class='m-2' />*/}
                  <IncomeChart theme={theme} profile={profile} prop={'income_statement_combined'} />
                </div>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  {/*<span class={ `bold` }>Expenses:</span>
                  <hr class='m-2' />*/}
                  <Expense theme={theme} profile={profile} prop={'income_statement_combined'} />
                </div>
              </div>
            </div>
            <div class='row no-gutters'>
              <div class='col-md-8'>
              <div class={ `p-2 round-corner-10 theme-sub-${ theme } mt-1` }>
                <BalanceSheet isSmall={process.env.PREACT_APP_MOBILE_BUILD} theme={theme} profile={profile} count={4 * 30} prop={'balance_sheet_fm'} />
              </div>
              </div>
              <div class='col-md-4'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  {/*<span class={ `bold` }>Margins and Revenue:</span>
                  <hr class='m-2' />*/}
                  <Debts profile={profile} prop={'balance_sheet_fm'} theme={theme} height={280} />
                </div>
                <LazyLoad><AdSense.Google
                  client='ca-pub-2231222372493371'
                  slot='3790812214'
                  style={{ display: 'block' }}
                  format='fluid'
                  responsive='true'
                /></LazyLoad>
              </div>
            </div>
            <div class='row no-gutters'>
              <div class='col-md-8'>
              <div class={ `p-2 round-corner-10 theme-sub-${ theme } mt-1` }>
                <CashFlows isSmall={process.env.PREACT_APP_MOBILE_BUILD} theme={theme} profile={profile} count={4 * 30} prop={'cashflow_statement_combined'} />
              </div>
              </div>
              <div class='col-md-4'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  {/*<span class={ `bold` }>Margins and Revenue:</span>
                  <hr class='m-2' />*/}
                  <CashflowChart theme={theme} profile={profile} prop={'cashflow_statement_combined'} />
                </div>
              </div>
            </div>
            {process.env.PREACT_APP_MOBILE_BUILD ? <LazyLoad><AdSense.Google
              client='ca-pub-2231222372493371'
              slot='3790812214'
              style={{ display: 'block' }}
              format='fluid'
              responsive='true'
            /></LazyLoad> : null}
          </div>
          {!process.env.PREACT_APP_MOBILE_BUILD ? <div class='col-md-8 mobile-hide p-1'>
            <div class={ `no-gutters round-corner-10 theme-sub-${ theme }` }>
              {profile ? <PriceLoader key={routeTicker} ticker={routeTicker} /> : null}
              {/*profile && chartPrice && chartPrice.length ? <Chart
                theme={ theme }
                isAdmin={ isAdmin( user ) }
                latestprice={ latestprice }
                dailyprice={ dailyprice }
                weeklyprice={ weeklyprice }
                monthlyprice={ monthlyprice }
                intradayPrice={ intradayPrice }
                ticker={ profile.ticker }
              /> : <div class='min-height-400' />*/}
            </div>
            <div class='row no-gutters mb-2'>
              {/*!process.env.PREACT_APP_MOBILE_BUILD ? ((profile && profile.misc_data || []).concat(profile && profile.misc_data_derived || [])).map(({type, value, abbr, is_highlow, show_abbr, is_inverted}) => {
                return <div class='col-md-6 mt-1 pr-1'>
                  <div class={ `no-gutters si-component-wrapper mb-2` }>
                    <GenericBar profile={profile} type={type} abbr={show_abbr === false ? null : abbr} is_inverted={is_inverted} value={deDupByYearMonth(value)} theme={theme} is_highlow={is_highlow} />
                  </div>
                </div>
              }) : null*/}
              {/*<div class='col-md-12 p-1'>
                <div class='p-1'>
                  <span class={ `bold` }>Latest News:</span>
                </div>
                <News profile={ profile } theme={ theme } />
              </div>*/}
              {/*<div class='col-md-6 p-1'>
                <div class='p-1'>
                  <span class={ `bold` }>Institution Ownership:</span>
                </div>
                <InstitutionOwnership profile={ profile } theme={ theme } />
              </div>*/}
              {profile && profile.insider_summary && profile.insider_summary.data ? <div class='col-md-12'>
                <div class='p-1'>
                  <span class={ `bold` }>Insider Ownership:</span>
                </div>
                <div class={ `mb-2 p-2 round-corner-10 theme-sub-${ theme }` }>
                  <Roster isLoggedin={user && user._id} data={ profile.insider_summary.data } theme={ theme } ticker={profile && profile.ticker} />
                </div>
              </div> : null}
              {profile && profile.insider_transactions && profile.insider_transactions.data ? <div class='col-md-12'>
                <div class='p-1'>
                  <span class={ `bold` }>Insider Transactions:</span>
                </div>
                <div class={ `mb-2 p-2 round-corner-10 theme-sub-${ theme }` }>
                  <Insiders isLoggedin={user && user._id} data={ profile.insider_transactions.data } theme={ theme } ticker={profile && profile.ticker} />
                </div>
              </div> : null}
              <div class='col-md-12'>
                {/*<div class='p-1'>
                  <span class={ `bold` }>Latest Posts:</span>
                </div>*/}
                {profile && profile.ticker ? <a href={`https://www.stockideashq.com/stocks/${profile.ticker.toLowerCase()}`} target='_blank'><div class='btn btn-success mt-2'>Get some stock ideas</div></a> : null}
                {/*profile && profile.ticker ? <Posts
                  key={ profile.ticker }
                  ticker={ profile.ticker }
                  user={user}
                  theme={ theme }
                  isTree
                  data={ posts }
                  closePostPosition={ this.props.closePostPosition }
                  switchPostPosition={ this.props.switchPostPosition }
                  proceed={ this.proceed }
                  context='posts'
                /> : null*/}
              </div>
            </div>
          </div> : null}
          <div class='col-md-4 pt-1'>
            <div class='row no-gutters mb-2'>
              {/*<div class='col-md-12 browser-hide'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <DownloadIOS />
                </div>
              </div>*/}
              {process.env.PREACT_APP_MOBILE_BUILD ? <div class='col-md-12 browser-hide'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Stock Price:</span>
                  <hr class='m-1' />
                  {profile ? <div class={`theme-darkred-${theme}`} style={{ fontWeight: 'bold' }}>{profile.ticker} - {profile.name} <span class={`theme-green-${theme}`}>Stock Price</span></div> : null}
                  {profile ? <LazyLoad><PriceLoader key={routeTicker} ticker={routeTicker} /></LazyLoad> : null}
                  {profile ? <LazyLoad height={250}><ChartSmall
                    theme={ theme }
                    weeklyprice={ weeklyprice.slice(-104) }
                    ticker={ profile.ticker }
                  /></LazyLoad> : null}
                </div>
              </div> : null}
              {(profile && profile.misc_data || []).map(({type, value, abbr, is_highlow, show_abbr, is_inverted}) => {
                return <div class='col-md-12'>
                  <div class={ `no-gutters si-component-wrapper mb-2` }>
                      <GenericBar profile={profile} type={type} abbr={show_abbr === false ? null : abbr} is_inverted={is_inverted} value={deDupByYearMonth(value)} theme={theme} is_highlow={is_highlow} />
                  </div>
                </div>
              })}
              

              <div class='col-md-12'>
                <LazyLoad><AdSense.Google
                  client='ca-pub-2231222372493371'
                  slot='3790812214'
                  style={{ display: 'block' }}
                  format='fluid'
                  responsive='true'
                /></LazyLoad>
              </div>
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Website Ranking Analysis:</span>
                  <hr class='m-2' />
                  <Alexa profile={profile} theme={theme} height={200} />
                </div>
              </div>*/}
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Google Trends Analysis:</span>
                  <hr class='m-2' />
                  <GoogleTrends profile={profile} theme={theme} height={200} />
                </div>
              </div>*/}
              
              <div class='col-md-12'>
                <LazyLoad><AdSense.Google
                  client='ca-pub-2231222372493371'
                  slot='3790812214'
                  style={{ display: 'block' }}
                  format='fluid'
                  responsive='true'
                /></LazyLoad>
              </div>
              <div class='col-md-12'>
                <LazyLoad><AdSense.Google
                  client='ca-pub-2231222372493371'
                  slot='3790812214'
                  style={{ display: 'block' }}
                  format='fluid'
                  responsive='true'
                /></LazyLoad>
              </div>
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <div>
                    <span class={ `bold` }>Capital Expenditure:</span>
                    <hr class='m-2' />
                    <GenericBar barColor='#dc143c' profile={profile} isBar type={'Capital Expenditure'} value={capex} theme={theme} />
                  </div>
                </div>
              </div>
              <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <div>
                    <span class={ `bold` }>Inventory Turnover:</span>
                    <hr class='m-2' />
                    <GenericBar barColor='#50C878' profile={profile} isBar type={'Inventory Turnover'} value={inventoryTurnover} theme={theme} />
                  </div>
                </div>
              </div>
              <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <div>
                    <span class={ `bold` }>Days Payable Outstanding:</span>
                    <hr class='m-2' />
                    <GenericBar barColor='#E97451' profile={profile} isBar type={'Days Payable Outstanding'} value={daysPayableOutstanding} theme={theme} />
                  </div>
                </div>
              </div>
              <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <div>
                    <span class={ `bold` }>Days Sales Outstanding:</span>
                    <hr class='m-2' />
                    <GenericBar barColor='#2E86C1' profile={profile} isBar type={'Days Sales Outstanding'} value={daysSalesOutstanding} theme={theme} />
                  </div>
                </div>
              </div>*/}
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Technical Price Pattern Alerts:</span>
                  <hr class='m-2' />
                  <div style={ { textAlign: 'center', padding: 10 } }>
                  <div style={ {
                    textAlign: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: 200, width: '100%', filter: 'blur(3px)', backgroundImage: `url('https://i.imgur.com/TXXdqF9.jpg')`,
                  } }
                  /><p style={ { position: 'absolute', top: 120, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto' } }>
                    <span class='font-12'>E-mail me when special technical pattern forms. </span><span class='font-25'>🔥</span><br style={{ height: 25 }}/>
                    <button class={ heartClass2 } onClick={ this.onClickAlert } ><FaHeart class='darkred' />&nbsp;{heartStr2}</button>
                  </p>
                  </div>
                </div>
              </div>*/}
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Short Analysis:</span>
                  <hr class='m-2' />
                  <Shorts profile={profile} theme={theme} />
                </div>
              </div>*/}
              {process.env.PREACT_APP_MOBILE_BUILD ? <div class='col-md-12 browser-hide'>
                {/*profile && profile.ticker ? <Posts
                  key={ profile.ticker }
                  ticker={ profile.ticker }
                  theme={ theme }
                  isTree
                  data={ posts }
                  closePostPosition={ this.props.closePostPosition }
                  switchPostPosition={ this.props.switchPostPosition }
                  proceed={ this.proceed }
                  context='posts'
                /> : null*/}
                {profile && profile.ticker ? <a href={`https://www.stockideashq.com/stocks/${profile.ticker}`} target='_blank'><div class='btn btn-success mt-2'>Get some stock ideas</div></a> : null}
              </div> : null}
              {/*<div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Ev / Market Capitalization Analysis:</span>
                  <hr class='m-2' />
                  <ImageWrapper ticker={profile && profile.ticker} url={evMarketCapImg} handle={'cashflow_table'} alt={ `${ profile && profile.ticker } ev and marketcap analysis` } />
                </div>
              </div>*/}
              {/* <div class='col-md-12'>
                <div class='no-gutters shadow bg-lightgray-ultra-5 mb-2 p-2'>
                  <div>
                    <div class='font-16 bold mb-2'>
                  Earnings Transcripts:
                </div>
                    <hr class='m-1' />
                    <Suspense fallback={<div>...</div>}>
                      <Transcripts profile={profile} />
                    </Suspense>
                    <div class='clear font-10'>Source: <a class='green' rel='noopener noreferrer' target='_blank' href='https://www.fool.com'>fool.com</a></div>
                  </div>
                </div>
              </div> */}
              {/* <div class='col-md-12'>
                <div class='no-gutters shadow bg-lightgray-ultra-5 mb-2 p-2'>
                  <div>
                    <div class='font-16 bold mb-2'>Technical Indicators:</div>
                  </div>
                  <Suspense fallback={<div>...</div>}>
                    <Technicals prices={dailypriceAdj} />
                  </Suspense>
                </div>
              </div> */}
              {/* <div class='col-md-12'>
                <div class={'no-gutters shadow mb-2 p-3 round-corner-10 theme-sub-' + theme}>
                  <div class='col-md-12'>
                    <div class='font-16 bold'>Competitors:</div>
                    <hr class='m-1' />
                    <Competitors data={_.get(profile, 'competitors.data', [])} proceed={this.proceed} />
                  </div>
                  <div class='col-md-12'>
                    <div style={{ height: 20 }} />
                  </div>
                  <div class='col-md-12'>
                    <div class='font-16 bold'>Suppliers:</div>
                    <hr class='m-1' />
                    <Competitors data={_.get(profile, 'suppliers.data', [])} proceed={this.proceed} />
                  </div>
                </div>
              </div> */}
              {/* <div class='col-md-12'>
                <div class='row no-gutters shadow bg-lightgray-ultra-5 mb-2 p-2'>
                  <div class='col-md-12'>
                    <div class='font-16 bold'>Insider Trading:</div>
                    <Suspense fallback={<div>...</div>}>
                      <Insider hideticker data={(profile && profile.insider && profile.insider.data || []).slice(0, 10).map(d => [profile.ticker].concat(d))} />
                    </Suspense>
                  </div>
                </div>
              </div> */}


              {/* isAdmin( user ) ? <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <div class='col-md-12'>
                    <span class={ `bold` }>Website Ranking:</span>
                    <hr class='m-1' />
                    {alexaUrl && profile ? <LazyLoad height={ 325 }><img
                        class='w-100'
                        alt={ `alexa website ranking for ${ profile.ticker } ${ profile.name }` }
                      src={ alexaUrl }
                    /></LazyLoad> : <span>Not available</span>}
                    <div class='clear font-10'>Source: <a class='green' rel='noopener noreferrer' target='_blank' href='https://www.alexa.com'>alexa.com</a></div>
                  </div>
                </div>
              </div> : null */}

              {/* <div class="col-md-12">
                <div class="no-gutters shadow bg-lightgray-ultra-5 mb-2 p-2 round-corner-10">
                        <div>
                          <span class="font-12 bold">Ownership:</span>
                          <hr class="m-2" />
                          <Roster profile={ profile } />
                      </div>
                    </div>
              </div> */}
              {/* <div class='col-md-12'>
                <div class={'no-gutters shadow mb-2 p-3 round-corner-10 theme-sub-' + theme}>
                  <span class={'font-12 bold theme-post-title' + theme}>Other Websites:</span>
                  <hr class='m-1' />
                  <OtherSites ticker={profile && profile.ticker} />
                </div>
              </div> */}
              {/* <div class='col-md-12'>
                <div class='no-gutters shadow bg-lightgray-ultra-5 mb-2 p-2'>
                  <div>
                    <div class='font-16 bold'>Patterns:</div>
                    <hr class='m-1' />
                    <Suspense fallback={<div>...</div>}>
                      <DailyPatterns viewPattern={this.viewPattern} onShift={this.onShift} pattern={pattern} data={dailypatternsAnother} />
                    </Suspense>
                  </div>
                </div>
              </div> */}
              {/*{peerRevenueGrowthImg ? <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Revenue Growth & Peers Analysis:</span>
                  <hr class='m-2' />
                  <ImageWrapper ticker={profile && profile.ticker} url={peerRevenueGrowthImg} handle={'peer_revenue_growth_yoy_img'} alt={ `${ profile && profile.ticker } revenue growth and peers analysis` } />
                </div>
              </div> : null}
              {peerPERetioImg ? <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>P/E Ratio & Peers Analysis:</span>
                  <hr class='m-2' />
                  <ImageWrapper ticker={profile && profile.ticker} url={peerPERetioImg} handle={'peer_pe_ratio_img'} alt={ `${ profile && profile.ticker } p/e ratio and peers analysis` } />
                </div>
              </div> : null}
              {peerPSRetioImg ? <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>P/S Ratio & Peers Analysis:</span>
                  <hr class='m-2' />
                  <ImageWrapper ticker={profile && profile.ticker} url={peerPSRetioImg} handle={'peer_ps_ratio_img'} alt={ `${ profile && profile.ticker } p/s ratio and peers analysis` } />
                </div>
              </div> : null}
              {peerGrossMarginImg ? <div class='col-md-12'>
                <div class={ `no-gutters si-component-wrapper mb-2` }>
                  <span class={ `bold` }>Gross Profit Margin & Peers Analysis:</span>
                  <hr class='m-2' />
                  <ImageWrapper ticker={profile && profile.ticker} url={peerGrossMarginImg} handle={'peer_gross_margin_img'} alt={ `${ profile && profile.ticker } gross profit margin and peers analysis` } />
                </div>
              </div> : null}*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}
