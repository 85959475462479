import APIUtils from './../../utils/APIUtils';
import _ from './../../utils/lodash';

export const getLikes = () => ( dispatch, getState ) => {
  const token = getState().token;
  if ( token ) {
    APIUtils.get( token, '/likes' ).then( ( info ) => {
      dispatch( {
        type: 'GET_MY_LIKES',
        payload: {
          likes: info || []
        },
      } );
    } ).catch( err => {
      console.log( err );
    } );
  } else {
    // you are not loggedin
  }
};

export const uploadImage = ( str ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/upload/image', { str } );

export const likePost = ( id ) => ( dispatch, getState ) => {
  const token = getState().token;
  if (!token) return;
  const likes = _.cloneDeep( getState().likes || [] );
  const justliked = _.cloneDeep( getState().justliked || [] );
  if ( likes.indexOf( id ) > -1 ) {
    return;
  }
  justliked.push( id );
  likes.push( id );
  dispatch( {
    type: 'JUST_LIKED',
    payload: { likes, justliked },
  } );
  return APIUtils.post( token, `/posts/${ id }/likes`, { } )
  .catch( err => {
    console.log( err );
  } );
};
