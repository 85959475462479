function PriceChange( props ) {
  const { close, prevClose } = props;
  if ( !close ) {
    return null;
  }
  if ( !prevClose ) {
    return null;
  }
  const change = ( close / prevClose ) - 1;
  const colorClass = change > 0 ? ( `green-blink green` ) : ( `red-blink red` );

  return (
    <span className={ `${ colorClass } bold` }>{( close || 0 ).toFixed( 2 )} ({`${ ( change * 100 ).toFixed( 3 ) } %`})</span>
  );
}

export default PriceChange;
