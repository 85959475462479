import { Component } from 'preact';
import { connect } from 'preact-redux';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';
import CompaniesBubble from './companies-bubble'
import style from './style.css';

import reduce from '../../reducers';
import * as writerActions from './../../actions/writers';

const companies = [{
  ticker: 'aapl',
  name: 'Apple Inc',
  logo_url: 'https://i.stack.imgur.com/mlUsd.png',
  num_followers: 202,
  num_posts: 100,
  medals: {
    gold: 4,
    silver: 5,
    copper: 2387
  },
  num_employees: 164000,
  sector: 'Technology',
  industry: 'Consumer Electronics',
  pretty_tags: ['Smart Phones', 'Nasdaq'],
  stats: {
    trades: ['SaaS', 'Forex'],
    n_followers: 50,
    n_posts: 100,
    n_replies: 200,
  }
}, {
  ticker: 'goog',
  name: 'Google Inc',
  logo_url: 'https://i.stack.imgur.com/dbUpU.png',
  num_followers: 313,
  num_posts: 21,
  medals: {
    gold: 2,
    silver: 5,
    copper: 2231
  },
  num_employees: 150028,
  sector: 'Technology',
  pretty_tags: ['Search Engine', 'Smart Phones', 'Nasdaq'],
  stats: {
    trades: ['SPY'],
    n_followers: 50,
    n_posts: 100,
    n_replies: 200,
  }
}, {
  ticker: 'amzn',
  name: 'Amazon Inc',
  logo_url: 'https://i.stack.imgur.com/zFdU5.png',
  num_followers: 99,
  num_posts: 26,
  medals: {
    gold: 3,
    silver: 5,
    copper: 612
  },
  num_employees: 1468000,
  sector: 'Technology',
  pretty_tags: ['Cloud Computing', 'E-Commerce', 'Nasdaq'],
  stats: {
    trades: ['NQ', 'SPY'],
    n_followers: 50,
    n_posts: 100,
    n_replies: 200,
  }
}]

@connect(reduce, writerActions)
export default class Landing extends Component {
  componentDidMount() {
    this.props.getInterestingCompanies();
  }

  render() {
    const { user, interesting_companies = [] } = this.props;

    return (
      <>
        <div class='row no-gutters'>
          <div class='col-md-10 offset-md-1'>
            <div class='row no-gutters'>
              {/*<div class='col-md-7'>
                <div class={ style['si-landing-image'] }>
                  <img src='https://i.stack.imgur.com/DePow.png' style={{width: '100%'}} />
                </div>
                <div class='center'>
                  <h1 class='font-30-vw'>Get stock ideas about companies</h1>
                  <h2 class='font-15-vw mt-3'>We help you learn to research companies with a group of serious investors</h2>
                </div>
              </div>
              <div class='col-md-5'>
                <div style={{ height: '60px' }} />
                {companies.map(company => <CompaniesBubble company={company} />)}
              </div>*/}
              <div class='col-md-12'>
                <div style={{ height: '60px' }} />
                <div class='row no-gutters'>
                {interesting_companies.map(company => <div class='col-md-3'><CompaniesBubble company={company} size={'small'} /></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
