import { Component } from 'preact';
import { Helmet } from 'react-helmet';
import { connect } from 'preact-redux';
import reduce from '../../reducers';
import Ticker from './../../components/ticker';
import * as tickerActions from './../../actions/tickers';

@connect(reduce, tickerActions)
export default class TickerPage extends Component {
  constructor( props ) {
    super( props );
    this.getTicker = this.getTicker.bind(this);
  }
  
  componentDidMount = () => {
    const ticker = this.props.ticker || 'aapl'
    this.getTicker(ticker);
  };

  getTicker = (ticker) => {
    const { user } = this.props;
    this.props.getTickerMain(ticker);
    this.props.getTickerRest(ticker);
    this.props.getTickerNumbers(ticker);
    // if (!process.env.PREACT_APP_MOBILE_BUILD) {
    //   this.props.getTickerPrice(ticker);
    // }
    // this.props.getTickerPosts(ticker);
    this.props.subscribeTickerPriceTopics( ticker );
    if (user && user._id) {
      this.props.recordSearchTicker(ticker)
    }
  };

  render(props) {
    const { theme, main = {}, rest = {}, numbers = {} } = this.props;
    const profile = { main, numbers, ...rest, }
    const title = profile.ticker ? `${ profile.ticker } - ${ profile.name } stock research tools` : `stock research tools`;
    const description = profile.description ? profile.description.slice( 0, 150 ) : 'We help you succeed in the stock market.';

    return (<div class={ `theme-${ theme }` }>
      {profile.ticker ? <Helmet>
        <title>{title}</title>
        <meta name='description' content={ description } />
        <meta name='keywords' content={ title } />
        <meta property='og:url' content={ `/stocks/${profile.ticker.toLowerCase()}` } />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={ description } />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={ description } />
        <link rel='canonical' href={ `/stocks/${ profile.ticker.toLowerCase() }` } />
      </Helmet> : null}
      <Ticker {...props} getTicker={this.getTicker} profile={profile} />
    </div>);
  }
}
