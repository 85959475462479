import store from 'store';
import _ from './../../utils/lodash';
import APIUtils from './../../utils/APIUtils';
import {
  expandScreener,
} from './../../utils/helper';

export const getSectorPerformance = () => ( dispatch, getState ) => APIUtils.get( getState().token, `/sector-performance` ).then( info => {
  dispatch({
    type: 'GET_SECTOR_PERFORMANCE', payload: { sectorPerformance: info }
  })
} );

export const getWordcloud = ( ticker ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/opinions` ).then( info => {
  const opinions = Object.keys(info || {}).map(ticker => ({ text: ticker, value: info[ticker]}))
  dispatch({
    type: 'GET_OPINIONS', payload: { opinions }
  })
} );

export const getSignals = ( shift = 0 ) => ( dispatch, getState ) => {
  APIUtils.get( getState().token, `/signals?shift=${shift}` )
    .then( signal => {
      dispatch( {
        type: 'GET_SIGNALS',
        payload: { shift, signal }
      } );
    } );
};

export const getLatestPatterns = ( ticker, shift ) => ( dispatch, getState ) => APIUtils.get( getState().token, '/patterns/latest' ).then( d => dispatch( {
  type: 'GET_LATEST_PATTERNS',
  payload: d,
} ) ).catch( err => {
  console.log( err );
} );

export const getLatestPatternsWeekly = ( ticker, shift ) => ( dispatch, getState ) => APIUtils.get( getState().token, '/patterns/latest-weekly' ).then( d => dispatch( {
  type: 'GET_LATEST_PATTERNS_WEEKLY',
  payload: d,
} ) ).catch( err => {
  console.log( err );
} );

const getScreenerFn = ( dispatch, getState ) => {
  const screener = store.get( 'screener' );
  // TODO get rid of the last condition later
  if ( !screener || screener.ts < Date.now() - ( 0 ) || typeof screener === 'string' ) {
    APIUtils.get( getState().token, '/statics/ticker-screener' ).then( info => {
      const { data, meta } = expandScreener(info);

      store.set( 'screener', { ts: Date.now(), data, meta } );
      dispatch( {
        type: 'GET_SCREERER_MAP_SUCCESS',
        payload: { data, meta },
      } );
    } ).catch(err => {
      dispatch( {
      type: 'GET_SCREERER_MAP_ERROR',
    } );
    })
  } else {
    dispatch( {
      type: 'GET_SCREERER_MAP_SUCCESS',
      payload: screener,
    } );
  }
};

export const getScreener = () => ( dispatch, getState ) => {
  getScreenerFn( dispatch, getState );
};

export const getTag = ( tagId ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/statics/tags/${ tagId }` ).then( d => dispatch( {
  type: 'GET_TAG',
  payload: d || [],
} ) ).catch( err => {
  console.log( err );
} );

export const getTagPosts = ( tag, skip = 0 ) => ( dispatch, getState ) => APIUtils.get( getState().token, `/posts/by_tag/${tag}?skip=${ skip }` ).then( d => {
  const posts = getState().tagposts || [];
  const tagposts = _.uniqBy( posts.concat( d || [] ), d => d._id );
  return dispatch( {
    type: 'GET_TAG_POSTS',
    payload: { tagposts },
  } );
} ).catch( err => {
  console.log( err );
} );

export const getTagStats = () => ( dispatch, getState ) => APIUtils.get( getState().token, '/statics/tagstats' ).then((d = []) => {
  const max = Math.max( ...d.map( d => d.heat ) );
  d = d.map( d => {
    d.heatNormalized = ( max ? d.heat / max : 0 ) * 10;
    return d;
  } );
  return dispatch( {
    type: 'GET_TAG_STATS',
    payload: d || [],
  } );
} ).catch( err => {
  console.log( err );
} );

export const getCCPageIncomeStatements = () => ( dispatch, getState ) => APIUtils.get( getState().token, `/cc/income-statements` ).then( info => {
  dispatch({
    type: 'GET_CC_INCOME_STATEMENTS', payload: { ccIncomeStatements: info }
  })
} );
