import { Component } from 'preact';
import { Link } from 'preact-router/match';
import GradientHide from './../gradienthide';

const financials = (number) => {
  if (!number) return;
  if (isNaN(number)) return;
  return Number(number).toLocaleString();
};

const title = (str) => {
  if (!str) return str;
  if (str.indexOf('Chief') === 0) {
    return str.split(' ').map(d => d[0]).join('') + ': '
  }
  return str + ': ';
}
class Insiders extends Component {
  render() {
    const { theme, data, ticker, isLoggedin } = this.props;
    if (!ticker) {
      return (
        <div>Not available at this time... </div>
      );
    }
    if (!data || !data.length) {
      return (
        <div>Not available at this time... </div>
      );
    }

    return (
      <div className='row m-1 row no-gutters'>
        <div className='col-md-12'>
          <GradientHide height={350} >
          <table className='table table-sm table table-hover font-10'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
                <th>Shares</th>
                <th>Avg Price</th>
                <th>Amount</th>
                <th>Reported Date</th>
              </tr>
            </thead>
            <tbody>
              {data.slice(0, isLoggedin ? 99999 : 5).map((d, i) => {
                return <tr key={i}>
                  <td><Link className={`theme-green-${theme} ml-1`} href={`/stocks/${ticker.toLowerCase()}/insiders/${d.personKey}`}>{d.person}</Link></td>
                  <td>{d.type}</td>
                  <td>{financials(d.deltaA)}</td>
                  <td>{d.price ? `$${financials(d.price.toFixed(2))}` : ''}</td>
                  <td>{d.deltaA < 0 && d.price ? `$${financials(Math.round(-d.deltaA * d.price))}` : ''}</td>
                  <td>{d.date}</td>
                </tr>
              })}
              <tr>
                <td>
                  <Link class='btn btn-warning m-2' href={'/login'}>Login to unlock all the metrics</Link>
                </td>
              </tr>
            </tbody>
          </table>
          </GradientHide>
        </div>
      </div>
    );
  }
}

export default Insiders;
