import { Component } from 'preact';
import { Helmet } from 'react-helmet';
const content = 'Time is valuable. We need an efficient way to gain financial insights quickly and accurately. Our tools help you deep dive into different industries. ';

import loadable from '@loadable/component';
const Sectors  = loadable(() => import('./../../components/sectors'));

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as miscActions from '../../actions/misc';

@connect(reduce, miscActions)
export default class SectorsPage extends Component {
  componentDidMount() {
    this.props.getTagStats();
  }

  render() {
    const { tagstats, theme = 'light' } = this.props;

    return (
      <div className={ `tags-panel-3 mt-2 theme-${ theme }` }>
        <Helmet>
          <title>Sectors & Industries Analysis</title>
          <meta name='description' content={ content } />
          <meta property='og:title' content={'Industries Research'} />
          <link rel='canonical' href='/sectors' />
        </Helmet>
        <div className='browser-hide'>
          <span className='font-14 darkred bg-yellow'>For the best user experience please use a browser to proceed.</span>
        </div>
        <div className='row no-gutters'>
          <div className='col-md-12'>
            <h1 className={`font-30 theme-darkred-${theme}`}>Sectors & Industries Analysis</h1>
            <h2 className='font-25'>Research the latest industry trends and gain some trading ideas early on. <span>🚀🚀🚀</span></h2>
            <div className='mb-1 font-14'>We display the 10 day / 30 day / 90 day avg return for the sector. There are a few sectors that (are expensive but) might have some hidden jewels in there, for example: </div>
            <ul className={`theme-green-${theme} font-14`}>
              <li>Solar</li>
              <li>Cancer Treatment</li>
              <li>Cancer Detection</li>
              <li>SaaS</li>
              <li>EV</li>
              <li>Metaverse</li>
              <li>Renewable Energy</li>
              <li>AI</li>
              <li>BNPL</li>
            </ul>
            <div className='mb-1 font-14'>These are things that would expect to get higher demand and adoption over time. But bear in mind past performance is a poor indicator of future performance do please do your due diligence when you invest in those sectors.</div>
            <hr className='mb-0' />
          </div>
          <div className='col-md-12'>
            <Sectors tagstats={ tagstats } />
          </div>
        </div>
      </div>
    );
  }
}
