import store from 'store';
import APIUtils from './../../utils/APIUtils';

export const getTickersMap = () => ( dispatch, getState ) => {
  getTickerMapFn( dispatch, getState );
};

const getTickerMapFn = ( dispatch, getState ) => {
  const tickersmap = store.get( 'tickersmap' );
  // TODO get rid of the last condition later
  if ( !tickersmap || tickersmap.ts < Date.now() - ( 86400000 * 7 ) || typeof tickersmap === 'string' ) {
    APIUtils.get( getState().token, '/statics/tickersmap' ).then( info => {
      const tickerdatainfo = Object.keys( info ).map( ticker => ( { name: `${ info[ ticker ] }`, value: ticker } ) );
      store.set( 'tickersmap', { ts: Date.now(), data: tickerdatainfo } );
      dispatch( {
        type: 'GET_TICKER_MAP_SUCCESS',
        payload: tickerdatainfo,
      } );
    } );
  } else {
    dispatch( {
      type: 'GET_TICKER_MAP_SUCCESS',
      payload: tickersmap.data,
    } );
  }
};
