/* eslint-disable max-len */
import { Component } from 'preact';
import store from 'store';
import FaInfoCircle from './../svg-components/fa-info-circle';

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as adminActions from '../../actions/admin';

const isAdmin = ( user ) => user && user.roles && user.roles.indexOf( 'admin' ) > -1;
const isMaintainer = ( user ) => user && user.roles && user.roles.indexOf( 'maintainer' ) > -1;

@connect(reduce, adminActions)
export default class AdminToggle extends Component {
  constructor( props ) {
    super( props );
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleAdminPanel = this.toggleAdminPanel.bind(this);

  }

  componentDidMount() {
    const {
      user
    } = this.props;
    if (!isAdmin( user ) && !isMaintainer( user )) return;
    if (document) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown(e) {
    const slashKey = 191
    const dotKey = 190
    if (e.keyCode == slashKey && global.ctrlDown) {
      this.toggleAdminPanel( 'isViewAdminPanel' )
    }
    if (e.keyCode == dotKey && global.ctrlDown) {
      this.toggleAdminPanel( 'isViewAdminMiscPanel' )
    }
  }

  toggleAdminPanel(str) {
    store.set( str, !store.get( str ) );
    if (window) {
      window.location.reload(); 
    }
  }

  render() {
    const {
      user
    } = this.props;
    if (!isAdmin( user ) && !isMaintainer( user )) return null;

    return (
      <FaInfoCircle className={ `mobile-hide white mr-2` } onClick={ () => this.toggleAdminPanel('isViewAdminPanel') } />
    );
  }
}
