import { Link } from 'preact-router/match';
import { Component } from 'preact';
import loadable from '@loadable/component';
import { route } from 'preact-router';
import style from './header.css';
import FaLightbulb from './../svg-components/fa-lightbulb';
import LoginButton from './login-button';
import MaybeWriteButton from './maybe-write-button';
import SaySomething from './say-something';
import AdminToggle from './admin-toggle';
const TickerBox  = loadable(() => import('./../../components/tickerbox'));

const onSuggestionSelected = ( { suggestionValue } ) => {
  route(`/stocks/${suggestionValue.toLowerCase()}`)
}
export default class Header extends Component {
	render() {
		return (
			<header class={style['si-header']}>
				<h3><Link href="/"><FaLightbulb color='lightgray' /><span class={`${style['si-brand']}`}>StockResearch</span></Link></h3>
				<nav class='si-vertical-center'>
					<AdminToggle />
					{/*<Link activeClassName={style['si-active']} class={`mobile-hide ${style['si-pickstr']}`} href="/stock-picking-game">2022 Stock Picking Game</Link>*/}
					{/*<Link activeClassName={style['si-active']} class={`mobile-hide`} href="/signals">Signals</Link>*/}
					<Link activeClassName={style['si-active']} class={`mobile-hide`} href="/sectors">Sectors</Link>
					<Link activeClassName={style['si-active']} class={`mobile-hide`} href="/screener">Screener</Link>
					<MaybeWriteButton />
					<LoginButton />
					{/*<SaySomething />*/}
				</nav>
				{!process.env.PREACT_APP_MOBILE_BUILD ? <div class='row no-gutters p-1 mb-1'>
          <div style={{ position: 'fixed', top: 10, zIndex: 999999, width: 300, left: 350 }} >
            <TickerBox
              // theme={ theme }
              // value={ tickerStr }
              onSuggestionSelected={ onSuggestionSelected }
              // onSelect={ this.onChangeTicker }
              // onUnfocus={ this.onUnfocus }
              // onFocus={ this.onFocus }
              // onKeyDown={ this.onKeyDown }
            />
          </div>
        </div> : null}
			</header>
		);
	}
}
