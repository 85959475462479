import { Component } from 'preact';
import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as systemActions from './../../actions/system';

@connect(reduce, systemActions)
export default class PostLikeTracker extends Component {
  componentDidMount( ) {
    if ( this.props.likes.length <= 0 ) {
      this.props.getLikes();
    }
  }

  render( ) {
    return (
      null
    );
  }
}
