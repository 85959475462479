import { Component } from 'preact';
import { connect } from 'preact-redux';
import { Link } from 'preact-router/match';
import _ from './../../utils/lodash';
import store from 'store';
import { Helmet } from 'react-helmet';
import reduce from '../../reducers';
import * as miscActions from '../../actions/misc';
import loadable from '@loadable/component';
const Screener  = loadable(() => import('./../../components/screener'));

const content = 'Discover the next big things in the stock market with our stock screener. You can set your search criterions based on your personalized filters.';

@connect(reduce, miscActions)
export default class ScreenerPage extends Component {
  componentDidMount() {
    this.props.getScreener();
  }
  
  render() {
    const { theme = 'light' } = this.props;

    return ( <div class={ `tags-panel-3 theme-${ theme }` } >
      <Helmet>
        <title>Stock Screener</title>
        <link rel='canonical' href='/screener' />
        <meta name='description' content={ 'We help you get trading ideas in the stock market.' } />
        <meta property='og:title' content={'Best Stock Screener for getting stock ideas'} />
        <meta property='og:image' content={'https://i.imgur.com/ck0e0GY.jpg'} />
        <meta property='twitter:title' content={'Best Stock Screener for getting stock ideas'} />
        <meta property='twitter:image' content={'https://i.imgur.com/ck0e0GY.jpg'} />
      </Helmet>
      <div class='browser-hide'>
        <span class='font-14 darkred bg-yellow'>For the best user experience please use a browser to proceed.</span>
      </div>
      <div class='row no-gutters'>
        <div class='col-md-12'>
          <h1 class={`font-30 theme-darkred-${theme}`}>Stock Screener 🔥</h1>
          <h2 class='font-25'>{ content }</h2>
          <h3 class='font-14'>Some interesting metrics to look for is as follows:</h3>
          <ul class={`theme-green-${theme} font-14`}>
            <li>revenue growth yoy / qoq</li>
            <li>gross / net income margins</li>
            <li>p/s, p/e ratios</li>
            <li>number of employees... etc </li>
          </ul>
          <h3 class='font-14'>Normally, you do not want to invest in companies with less than 10 people or has decelerating revenue growth. On the other hand if a company is growing 100% a year in revenue then maybe that means they have some sort of product market fit. Use the stock screener wisely and do your own due diligence!</h3>
          <hr class='mb-0' />
        </div>
        <Screener {...this.props} />
      </div>
    </div> );
  }
}
