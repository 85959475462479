import { Component } from 'preact';
import { Helmet } from 'react-helmet';
import _ from './../../utils/lodash';
import { Link } from 'preact-router/match';
// import RevenueGrowthBarChart from 'react-component-peer-comparison';
// import {
//   getScreener,
//   getTag,
//   getTagPosts,
// } from '../../reduxStore';
import loadable from '@loadable/component';
const Sector  = loadable(() => import('./../../components/sectors/sector'));

// const image = 'https://i.imgur.com/0aCDtsj.jpg';

// const keyify = ( str ) => str.replace( / /g, '-' );

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as miscActions from '../../actions/misc';

@connect(reduce, miscActions)
export default class SectorPage extends Component {
  componentDidMount() {
    const sector = this.props.sector
    this.props.getTag( sector );
    this.props.getTagPosts( sector, 0 );
  }

  render() {
    const { tag, screener, theme = 'light', tagposts = [] } = this.props;
    if ( !tag ) {
      return null;
    }
    const data = _.sortBy( tag.info || [], d => -d.price / d.prev );
    const meta = screener && screener.meta;
    const screenerDerived = ( screener && screener.data || [] ).reduce( ( t, d ) => {
      t[ d[ 0 ] ] = {
        tags: d[ meta.indexOf( 'tags' ) ],
        revenueGrowthYoY: d[ meta.indexOf( 'revenueGrowthYoY' ) ] * 100,
        peTrailing: d[ meta.indexOf( 'peTrailing' ) ],
        priceToSales: d[ meta.indexOf( 'priceToSales' ) ],
        grossMargin: d[ meta.indexOf( 'grossMargin' ) ] * 100,
        profitMargin: d[ meta.indexOf( 'profitMargin' ) ] * 100,
      };
      return t;
    }, {} );
    const dataBig = data.map( d => ( { ...d, ...screenerDerived[ d.ticker ] } ) );
    const dataBig1 = dataBig.map(d => ({...d, value: d.revenueGrowthYoY}))
    const dataBig2 = dataBig.map(d => ({...d, value: d.peTrailing}))
    const dataBig3 = dataBig.map(d => ({...d, value: d.priceToSales}))
    const dataBig4 = dataBig.map(d => ({...d, value: d.grossMargin}))
    const dataBig5 = dataBig.map(d => ({...d, value: d.profitMargin}))
    const image = tag && tag.image || 'https://i.imgur.com/0aCDtsj.jpg';

    return (
      <div className={ `tags-panel-3 mt-2 theme-${ theme }` }>
        <Helmet>
          <title>{`StockIdeas Sectors & Industries Research - ${ tag.name }`}</title>
          <meta name='description' content={ 'StockIdeas Industry Research - ' + (tag.description || tag.name) } />
          <meta name='keywords' content={ tag.description } />

          <meta property='og:image' content={ image } />
          <meta property='og:title' content={ `StockIdeas Stock Research - ${ tag.name }` } />
          <meta property='og:description' content={ tag.description } />
          <meta property='twitter:image' content={ image } />
          <meta property='twitter:title' content={ `StockIdeas Stock Research - ${ tag.name }` } />
          <meta property='twitter:description' content={ tag.description } />
          <link rel='canonical' href={ `/sectors/${ this.props.sector }` } />
        </Helmet>
        <div className='browser-hide'>
          <span className='font-14 darkred bg-yellow'>For the best user experience please use a browser to proceed.</span>
        </div>
        <div>
          <h1 className={`font-25 theme-darkred-${theme}`}>{tag.name}</h1>
          <h2 className='font-25'>Industry Research - {tag.description || tag.name}</h2>
          <hr />
        </div>
        {/*{dataBig1 && dataBig1.length > 10 ? <RevenueGrowthBarChart sector={tag.name} data={ dataBig1 } name={'Revenue Growth Rate YoY'} color={'rgba(138,17,31,0.4)'} tickercolor={'rgba(138,17,31,1)'} /> : null}
        {dataBig2 && dataBig2.length > 10 ? <RevenueGrowthBarChart sector={tag.name} data={ dataBig2 } name={'P/E Ratio ttm'} color={'rgba(18,28,138,0.4)'} tickercolor={'rgba(18,28,138,1)'} /> : null}
        {dataBig3 && dataBig3.length > 10 ? <RevenueGrowthBarChart sector={tag.name} data={ dataBig3 } name={'P/S Ratio ttm'} color={'rgba(118,28,138,0.4)'} tickercolor={'rgba(118,28,138,1)'} /> : null}
        {dataBig4 && dataBig4.length > 10 ? <RevenueGrowthBarChart sector={tag.name} data={ dataBig4 } name={'Latest Quarter Gross Profit Margin'} color={'rgba(18,138,31,0.4)'} tickercolor={'rgba(18,138,31,1)'} /> : null}
        {dataBig5 && dataBig5.length > 10 ? <RevenueGrowthBarChart sector={tag.name} data={ dataBig5 } name={'Latest Quarter Net Profit Margin'} color={'rgba(222,133,20,0.4)'} tickercolor={'rgba(222,133,20,1)'} /> : null}*/}
        <Sector {...this.props} />
        <Link className='btn btn-light btn-round font-12 mb-3' hre='/sectors'>Back to previous page</Link>
      </div>
    );
  }
}
