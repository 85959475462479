import { Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './header.css';
import { connect } from 'preact-redux';
import { route } from 'preact-router';
import reduce from '../../reducers';
import * as authActions from './../../actions/auth';

const isWriter = ( user ) => user && user.roles && user.roles.indexOf( 'writer' ) > -1;

@connect(reduce, authActions)
export default class MaybeWriteButton extends Component {
  render() {
    const { user } = this.props;

    if ( isWriter(user) ) {
      return (
        <div class={`mobile-hide btn btn-warning font-12-vw`} onClick={() => route('/createpost')}>Write</div>
      );
    } else {
      return (
        <Link activeClassName={style['si-active']} class={`mobile-hide font-12-vw`} href="/become-a-writer">Become a Writer</Link>
      )
    }
  }
}
