import { timeout } from 'promise-timeout';
// import { toast } from 'react-toastify';

const apiUrl = typeof window !== 'undefined' ? (process.env.PREACT_APP_API_URL_PROXY || process.env.PREACT_APP_API_URL) + 'api' : 'https://api.stockideashq.com/api';

const checkStatus = response => {
  if ( response.status >= 200 && response.status <= 304 ) {
    return response;
  }
  if ( response.status === 429 ) {
    // toast.error( '⚠️ Too many requests. Please try again in a bit.');
  }
  return response.json().then( d => {
    const error = new Error( d.error );
    error.status = response.status;
    throw error;
  } );
};

const APIUtilsFn = {
  get: ( token, endpoint ) => {
    const url = `${ apiUrl }${ endpoint }`;
    return fetch( url, {
      headers: {
        Authorization: `Bearer ${ token }`,
        'Content-Type': 'application/json',
      },
    } )
      .then( checkStatus )
      .then( response => response.json() );
  },
  post: ( token, endpoint, body, query ) => {
    const url = `${ apiUrl }${ endpoint }`;

    return fetch( url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${ token }`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( body ),
    } )
      .then( checkStatus )
      .then( response => response.json() );
  },
  put: ( token, endpoint, body, query ) => {
    const url = `${ apiUrl }${ endpoint }`;

    return fetch( url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${ token }`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( body ),
    } )
      .then( checkStatus )
      .then( response => response.json() );
  },
  del: ( token, endpoint ) => {
    const url = `${ apiUrl }${ endpoint }`;

    return fetch( url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${ token }`,
        'Content-Type': 'application/json',
      },
    } )
      .then( checkStatus )
      .then( response => response.json() );
  },
};

const APIUtils = {
  get: (token, endpoint, options = {}) => {
    if (options.retry && options.retry > 0) {
      return timeout(APIUtilsFn.get(token, endpoint, options), 4000)
      .catch(err => {
        return APIUtils.get(token, endpoint, {...options, retry: options.retry - 1});
      })
    } else {
      return timeout(APIUtilsFn.get(token, endpoint, options), 4000)
    }
  },
  put: (token, endpoint, body, query) => {
    return timeout(APIUtilsFn.put(token, endpoint, body, query), 4000)
  },
  post: (token, endpoint, body, query) => {
    return timeout(APIUtilsFn.post(token, endpoint, body, query), 4000)
  },
  del: (token, endpoint) => {
    return timeout(APIUtilsFn.del(token, endpoint), 4000)
  }
}
export default APIUtils;
