import _ from './utils/lodash';
import store from 'store';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

const transformMessage = (d) => {
  return {
        _: 'message',
        id: d._id,
        from_id: { _: 'peerUser', username: d.user.username },
        // peer_id: { _: 'peerChannel', channel_id: d._chat._id },
        date: d.inserted_at,
        message: d.text,
        image: d.image,
        media: {
          video: d._video,
          photo: d._photo,
          document: d._sticker,
        }
      }
}

let ACTIONS = {
  ADD_TODO: ({ todos, ...state }, { text }) => ({
    todos: [...todos, {
      id: Math.random().toString(36).substring(2),
      text
    }],
    ...state
  }),

  REMOVE_TODO: ({ todos, ...state }, { todo }) => ({
    todos: todos.filter( i => i!==todo ),
    ...state
  }),

  GET_TAG_STATS: (state, action) => ({
    ...state,
    tagstats: action.payload
  }),

  GET_TAG: (state, action) => ({
    ...state,
    tag: action.payload
  }),

  ENTER_GAME_SUCCESS: (state, action) => ({
    ...state,
    ...action.payload,
    isEntered: true
  }),

  GET_LATEST_GAME_SUCCESS: (state, action) => ({
    ...state,
    game: action.payload
  }),

  GET_MY_GAME_SUCCESS: (state, action) => {
    if (action.payload.fn) {
      action.payload.fn(action.payload);
    }
    return {
      ...state,
      myGame: action.payload
    }
  },

  USER_VERIFIED: (state, action) => ({
    ...state,
    isVerified: true, errorMessage: null
  }),

  USER_TOKEN_REMOVE: (state, action) => {
    store.set( 'token', null );
    return {
      ...state,
      user: {}, token: null
    }
  },

  USER_TOKEN_GET: (state, action) => {
    const token = action.payload.token;
    if (token) {
      store.set( 'token', token );
    }
    return {
      ...state,
      ...action.payload
    }
  },

  GET_OPINIONS: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_SECTOR_PERFORMANCE: (state, action) => ({
    ...state,
    ...action.payload
  }),

  ME_GET: (state, action) => ({
    ...state,
    user: action.payload
  }),

  TARGET_GET: (state, action) => ({
    ...state,
    targetuser: action.payload
  }),

  GET_TAG_POSTS: (state, action) => ({
    ...state,
    tagposts: action.payload.tagposts
  }),

  INTERESTING_COMPANIES_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  WRITER_GET: (state, action) => ({
    ...state,
    writer: action.payload
  }),

  WRITERS_GET: (state, action) => ({
    ...state,
    writers: action.payload
  }),

  WRITER_POSTS_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  SUBSCRIBE_TOPICS: (state, action) => {
    if ( !state.channel ) {
      console.log( 'channel is not there.' );
      return state;
    }
    state.channel.push( 'subscribe', action.payload );
    return state;
  },

  GET_TICKER_CHART: (state, action) => {
    const latestprice = _.pick( action.payload, [ 'open', 'high', 'low', 'close', 'date', 'dateshort', 'volume', 'prev_close' ] );
    return { ...state, latestprice, intradayPrice: action.payload.data };
  },

  GET_QUOTES: (state, action) => {
    const quotesPrev = state.quotes || {};
    const quotes = _.merge( _.cloneDeep( quotesPrev ), _.cloneDeep( action.payload ) );
    _.each( quotes, ( { price, prev }, ticker ) => {
      if ( quotesPrev[ ticker ] ) {
        if ( price > quotesPrev[ ticker ].price ) {
          quotes[ ticker ].blinkClass = 'si-green-blink';
        }
        if ( price < quotesPrev[ ticker ].price ) {
          quotes[ ticker ].blinkClass = 'si-red-blink';
        }
      }
      quotes[ ticker ].change = ( price - prev ) / prev * 100;
      quotes[ ticker ].ticker = ticker;
    } );
    return {
      ...state,
      quotes,
    };
  },

  GET_TICKER: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_POSTS: (state, action) => ({
    ...state,
    posts: action.payload.posts
  }),

  GET_LATEST_POSTS: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_ARTICLE: (state, action) => ({
    ...state,
    ...action.payload
  }),

  USER_INVENTORY_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  TARGET_USER_INVENTORY_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_LATEST_POSTS_START: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_POST_CHARTS: (state, action) => ({
    ...state,
    charts: action.payload.charts
  }),

  GET_TICKER_INSIDER_PERSON_ENTRY: (state, action) => ({
    ...state,
    ...action.payload
  }),

  MY_POSTS_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  TARGET_POSTS_GET: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_MY_LIKES: (state, action) => ({
    ...state,
    ...action.payload
  }),

  JUST_LIKED: (state, action) => ({
    ...state,
    ...action.payload
  }),

  GET_TICKER_POSTS: (state, action) => ({
    ...state,
    tickerposts: action.payload.tickerposts
  }),

  GET_TICKER_PRICES: (state, action) => ({
    ...state,
    dailyprice: action.payload.daily,
    weeklyprice: action.payload.weekly,
    monthlyprice: action.payload.monthly,
  }),

  GET_TICKER_MAP_SUCCESS: (state, action) => ({
    ...state,
    tickersmap: action.payload,
  }),

  GET_SCREERER_MAP_SUCCESS: (state, action) => ({
    ...state,
    screener: action.payload,
  }),

  GET_LATEST_PATTERNS: (state, action) => ({
    ...state,
    latestpatterns: action.payload,
  }),

  GET_LATEST_PATTERNS_WEEKLY: (state, action) => ({
    ...state,
    latestpatternsweekly: action.payload,
  }),

  SOCKET_SET: (state, action) => ({
    ...state,
    socket: action.payload
  }),

  CHANNEL_SET: (state, action) => ({
    ...state,
    channel: action.payload
  }),

  GET_SIGNALS: (state, action) => {
    const shift = action.payload.shift;
    const signals = _.cloneDeep(state.signals);
    signals[shift] = action.payload.signal || {};
    const returns = signals[shift].signals.map(d => d.ret);
    signals[shift].avgReturn = returns.length ? (_.sum(returns) / returns.length).toFixed(2) : 0;
    return {
      ...state,
      signals,
    }
  },

  GET_PRESENCE: (state, action) => {
    let users = [];
    action.payload.list((id, { metas: [first, ...rest] }) => {
      users.push(first);
    });

    return {
      ...state,
      onlineUsers: users
    };
  },

  CREATE_POST_SUCCESS: (state, action) => {
    toast.success( 'You have successfully created this post, please refresh your page.' );
    return state;
  },

  EDIT_POST_SUCCESS: (state, action) => {
    toast.success( 'You have successfully edited this post, please refresh your page.' );
    return state;
  },  

  CREATE_POST_ERROR: (state, action) => {
    toast.error( action && action.payload || 'Error creating a post.' );
    return state;
  },

  TELEGRAM_ADD_MESSAGE: (state, action) => {
    // toast.error( action && action.payload || 'Error creating a post.' );
    return state;
  },

  TELEGRAM_SET_MESSAGES: (state, action) => {
    const info = action.payload
    const hasNewMessages = action.payload.hasNewMessages
    const isSend = action.payload.isSend
    
    const messages = (info.messages || []).map(d => {
      return transformMessage(d)
    }).reverse();
    if (!messages || !messages.length) return state
    const oldMessages = state.telegram.messages || [];
    const oldUsers = state.telegram.users || {};
    const messagesOffsetStart = messages[0].id
    const messagesOffsetEnd = messages[messages.length - 1].id
    const users = (info.messages || []).reduce((t, d) => {
      t[d.user.username] = {
        photo_url: d.user.avatar,
        username: d.user.username,
      }
      return t;
    }, {})
    const newMessages = hasNewMessages || isSend ? _.uniqBy(oldMessages.concat(messages), d => d.id) : _.uniqBy(messages.concat(oldMessages), d => d.id)
    const newUsers = _.merge(oldUsers, users)
    // dispatch( {
    //   type: 'TELEGRAM_SET',
    //   payload: { 
    //     users: newUsers, 
    //     messages: newMessages, 
    //     isLoadingMessages: false,
    //     messagesOffsetStart,
    //     messagesOffsetEnd 
    //   },
    // } );
    const telegram = {...state.telegram,  
        hasNewMessages,
        users: newUsers, 
        messages: newMessages, 
        isLoadingMessages: false,
        messagesOffsetStart,
        messagesOffsetEnd 
      }

    // toast.error( action && action.payload || 'Error creating a post.' );
    // console.log(action.payload)
    return {...state, telegram};
  },

  GET_CC_INCOME_STATEMENTS: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  TELEGRAM_SET: (state, action) => ({
    ...state,
    telegram: {...state.telegram, ...action.payload},
  }),

  TELEGRAM_UNSET_NEW_MESSAGES: (state, action) => ({
    ...state,
    telegram: {...state.telegram, hasNewMessages: false},
  }),
};

const getUserFromToken = (token) => {
  try {
    const user = jwtDecode( token );
    if (!user || !user._id || user.exp < Date.now() / 1000) {
      // store.set( 'token', null );
      return { user: {}, token: null }
    }
    return { user, token }
  } catch (err) {
    return { user: {}, token: null }
  }
}

const tickersmap = store.get( 'tickersmap' )
const screener = store.get( 'screener' )

const INITIAL = {
  theme: 'light',
  likes: [],
  justliked: [],
  tickersmap: tickersmap && tickersmap.data || [{value: "AAPL", name: "Apple Inc."}, {value: "GOOG", name: "Alphabet Inc."}, {value: "NVDA", name: "Nvidia"}, {value: "AMZN", name: "Amazon.com, Inc"}, {value: "TSLA", name: "Tesla, Inc."}],
  screener: screener && screener.data,
  ...getUserFromToken(store.get('token')),
  profile: {},
  users: [],
  todos: [],
  latest: [],
  signals: [],
  telegram: {
    channelUsername: 'stockideashq'
  }
};

export default createStore( (state, action) => (
  action && ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state
), INITIAL, applyMiddleware( thunkMiddleware ));
