import { Component, Fragment } from 'preact';
import { Link } from 'preact-router/match';
// import StarRatings from 'react-star-ratings';
import FaTelegram from './../svg-components/telegram';
import FaLinkedin from './../svg-components/linkedin';
import FaFacebook from './../svg-components/facebook';
import FaWhatsapp from './../svg-components/whatsapp';
import FaTwitter from './../svg-components/twitter';
import FaPinterest from './../svg-components/pinterest';
import FaInstagram from './../svg-components/instagram';
import FaGlassdoor from './../svg-components/glassdoor';
import FaGithub from './../svg-components/github';
import style from './company.css';

// const scoreColor = (v) => {
//   if (v >= 4) return 'green'
//   if (v >= 3.5) return 'limegreen'
//   if (v > 3) return '#F4D03F'
//   if (v > 2) return 'orange'
//   if (v >= 1) return 'red'
//   return red
// }
const countryTags = [ 'Hong Kong', 'Argentina', 'Singapore', 'Sweden', 'Canadian', 'Israel', 'Chinese', 'Brazil', 'Taiwan', 'Switzerland', 'Japan', 'Germany', 'Belgium', 'France', 'United Kingdom', 'Denmark', 'Netherlands', 'India', 'Australia', 'Norway', 'South Korea', 'Uruguay'];
const financials = ( volume ) => {
  if ( !volume ) {
    return;
  }
  return Number( volume ).toLocaleString();
};

const calculateKey = ( str ) => str.toLowerCase().split( ' ' ).join( '-' ).replace( /&-/g, '' )
  .replace( /'/g, '' );

const socialFb = ( url ) => ( url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaFacebook className={ `si-facebook font-15` } />
  </a>
</div> : null );

const socialLinkedin = ( url ) => ( url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaLinkedin className={ `si-linkedin font-15` } />
  </a>
</div> : null );

const socialGithub = ( url ) => ( url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaGithub className={ `si-github font-15` } />
  </a>
</div> : null );

const socialInstagram = ( url ) => ( url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaInstagram className={ `si-instagram font-15` } color='green' />
  </a>
</div> : null );

const socialGlassdoor = ( url ) => ( url ? <div class='font-15 mt-1 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaGlassdoor className={ `si-glassdoor font-15` } />
  </a>
</div> : null );

const socialTwitter = ( url ) => ( url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <FaTwitter className={ `si-twitter font-15` } />
  </a>
</div> : null );

const socialBlind = ( url ) => (url ? <div class='font-15 inline-block mr-2'>
  <a target='_blank' rel='noopener noreferrer' href={ url }>
    <img src='/blind.ico' style={{ width: '15px', height: '15px' }}/>
  </a>
</div> : null);

const calculateFounderOfficers = (meta, theme = 'light') => {
  const founder = meta.founder || meta.Founder;
  const founder_linkedin = meta.founder_linkedin;
  const cofounder = meta.cofounder || meta['Co-Founder'];
  const ceo = meta.ceo || meta.Ceo;
  const ceo_linkedin = meta.ceo_linkedin;
  const ceo_since = meta.ceo_since;
  const ceoSinceComponent = meta.ceo_since ? <span className='ml-1 italic'>({ceo_since}~)</span> : null;
  const ceoLinkedinComponent = ceo_linkedin ? ( <a target='_blank' rel='noopener noreferrer' href={ ceo_linkedin }><FaLinkedin className={ `company-profile-linkedin theme-social-${ theme }` } /></a> ) : null;
  const cto = meta.cto;
  const cto_linkedin = meta.cto_linkedin;
  const ctoLinkedinComponent = cto_linkedin ? ( <a target='_blank' rel='noopener noreferrer' href={ cto_linkedin }><FaLinkedin className={ `company-profile-linkedin theme-social-${ theme }` } /></a> ) : null;
  const cfo = meta.cfo;
  const cfo_linkedin = meta.cfo_linkedin;
  const cfoLinkedinComponent = cfo_linkedin ? ( <a target='_blank' rel='noopener noreferrer' href={ cfo_linkedin }><FaLinkedin className={ `company-profile-linkedin theme-social-${ theme }` } /></a> ) : null;
  const cro = meta.cro;
  const cro_linkedin = meta.cro_linkedin;
  const croLinkedinComponent = cro_linkedin ? ( <a target='_blank' rel='noopener noreferrer' href={ cro_linkedin }><FaLinkedin className={ `company-profile-linkedin theme-social-${ theme }` } /></a> ) : null;
  const founderLinkedinComponent = founder_linkedin ? ( <a target='_blank' rel='noopener noreferrer' href={ founder_linkedin }><FaLinkedin className={ `company-profile-linkedin theme-social-${ theme }` } /></a> ) : null;
  const founderComponent = founder ? <div><b>Founder:&nbsp;</b><span>{founder}</span>&nbsp;{founderLinkedinComponent}<br /></div> : null
  const cofounderComponent = cofounder ? <div><b>Co-Founder:&nbsp;</b><span>{cofounder}</span><br /></div> : null
  const ceoComponent = ceo ? <div><b>CEO:&nbsp;</b><span>{ceo}</span>&nbsp;{ceoLinkedinComponent}{ceoSinceComponent}<br /></div> : null
  const cfoComponent = cfo ? <div><b>CFO:&nbsp;</b><span>{cfo}</span>&nbsp;{cfoLinkedinComponent}<br /></div> : null
  const ctoComponent = cto ? <div><b>CTO:&nbsp;</b><span>{cto}</span>&nbsp;{ctoLinkedinComponent}<br /></div> : null
  const croComponent = cro ? <div><b>CRO:&nbsp;</b><span>{cro}</span>&nbsp;{croLinkedinComponent}<br /></div> : null
  const ceoFounderComponent = ceo && founder && ceo === founder ? <div><b>CEO / Founder:&nbsp;</b><span>{founder}</span>&nbsp;{ceoLinkedinComponent}<br /></div> : null
  const ceoCoFounderComponent = ceo && cofounder && ceo === cofounder ? <div><b>CEO / Co-Founder:&nbsp;</b><span>{cofounder}</span>&nbsp;{ceoLinkedinComponent}<br /></div> : null
  
  return <Fragment>
    {ceoFounderComponent}
    {ceoCoFounderComponent}
    {!ceoFounderComponent && !ceoCoFounderComponent ? founderComponent : null}
    {!ceoFounderComponent && !ceoCoFounderComponent ? cofounderComponent : null}
    {!ceoFounderComponent && !ceoCoFounderComponent ? ceoComponent : null}
    {cfoComponent}
    {ctoComponent}
    {croComponent}
  </Fragment>
}

const curtail = ( str ) => {
  if ( !str ) {
    return str;
  }
  return str.length > 450 ? `${ str.slice( 0, 450 ) }...` : str;
};

const tagColor = ( tag ) => {
  let str = 'font-10 mr-1 mb-1 btn btn-sm btn-round-sm '
  if ( countryTags.indexOf( tag ) > -1 ) {
    str += 'btn-warning';
  }
  else if ( tag === 'ETF' || tag === 'SPAC' ) {
    str += 'btn-info';
  } else {
    str += 'btn-success';
  }
  return str;
};

class Company extends Component {
  render() {
    const { profile, theme } = this.props;
    if ( !profile || !profile.main ) {
      return <div className={ `shadow mb-2 round-corner-10 theme-sub-${ theme } min-height-250` } />;
    }
    const ticker = profile.ticker || '';
    const main = profile.main || {};
    const social = main.social || {};

    return (
      <div className={ 'si-component-wrapper' }>
        <div className='row no-gutters pl-2 pr-2 pt-2'>
          <div className='col-md-8 col-sm-8'>
            {main.name ? <div className='font-20 margin-top-20 font-lucida'>{main.name}</div> : <div class='round-corner-5 bg-lightgray' style={{ width: 200, height: 20}} />}
            {main.name ? <div>({main.exchange}:{main.ticker})</div> : <div class='round-corner-5 bg-lightgray' style={{ width: 40, height: 16}} />}
          </div>
          <div className='col-md-4 col-sm-4'>
            <div className='bg-white round-corner-10 p-1'>
              {main.img_url ? <img alt={ main.name } src={ main.img_url } style={ { width: '100%', height: 80, objectFit: 'contain' } } /> : null}
            </div>
          </div>
          <div className='col-md-12'>
            <hr />
          </div>
          <h6 className='col-md-12 line-height-1-7'>
            {main.name ? curtail( main.description ) : <div>
              {[0,0,0,0,0,0].map(d => <div class='round-corner-5 bg-lightgray m-1' style={{ width: '100%', height: 18}} />)}
              <div class='round-corner-5 bg-lightgray m-1' style={{ width: '30%', height: 18}} />
            </div>}
          </h6>
          <div className='col-md-12 line-height-1-7'>
            {main.url ? <div><b>Website: </b><a href={ main.url } rel='noopener noreferrer' target='_blank' className={`theme-blue-${theme}`}>{main.url}</a></div> : null}
            {main.founded ? <div><b>Founded: </b><span>{main.founded}</span><br /></div> : null}
            {main.ipo_info && main.ipo_info.ipo_date ? <div><b>IPO date: </b>{main.ipo_info.ipo_date}</div> : null}
            {main.ipo_info && main.ipo_info.ipo_price ? <div><b>IPO price: </b>${main.ipo_info.ipo_price}</div> : null}
            {main.number_of_employees ? <div><b>Full Time Employees: </b>{financials(main.number_of_employees)}</div> : null}
            {calculateFounderOfficers(main.meta || {})}
            {Object.keys(main.meta || {}).map( key => {
              let val = main.meta[key];
              let lowercaseKey = key.toLowerCase()
              if ( key === 'Full Time Employees' ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'founder' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'co-founder' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'ceo' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'cfo' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'cto' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'cro' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'cio' ) === 0 ) {
                return null;
              }
              if ( lowercaseKey.indexOf( 'founded' ) === 0 ) {
                return null;
              }
              return <div key={ key }><b>{key}: </b><span>{val}</span><br /></div>;
            } )}
            {main.earnings_date ? <div><b>Next Earnings Date: </b><span>{main.earnings_date}</span></div> : null}
            {main.keywords ? <div><b>Keywords: </b><span className={`theme-green-${theme}`}>{main.keywords.join( ', ' )}</span></div> : null}

            {main.tags ? <div className='mt-1'>{main.tags.slice(0, 3).map( ( d, i ) => <Link key={ i } href={ `/sectors/${ calculateKey( d ) }` } class={ tagColor( d ) }>{d}</Link> )}
                            </div> : null}

            {/*<div className='mt-1 bold'>Summary and stock analysis:</div>
            <ul className='font-12 mb-0 shited-ul'>
            {/*main.numbers && main.numbers.score_dcf ? <li>Discounted cash flow analysis score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_dcf }
              starRatedColor={scoreColor(main.numbers.score_dcf)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null
            {main.numbers && main.numbers.score_roe ? <li>Return on equity score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_roe }
              starRatedColor={scoreColor(main.numbers.score_roe)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            {main.numbers && main.numbers.score_roa ? <li>Return on assets score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_roa }
              starRatedColor={scoreColor(main.numbers.score_roa)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            {main.numbers && main.numbers.score_pe ? <li>P/E ratio score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_pe }
              starRatedColor={scoreColor(main.numbers.score_pe)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            {main.numbers && main.numbers.score_pb ? <li>P/B ratio score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_pb }
              starRatedColor={scoreColor(main.numbers.score_pb)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            {main.numbers && main.numbers.score_ps ? <li>P/S ratio score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_ps }
              starRatedColor={scoreColor(main.numbers.score_ps)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            {main.numbers && main.numbers.score_rev_growth ? <li>Revenue growth score: <div style={{display: 'inline-block', transform: 'translateY(-2px)'}}><StarRatings
              rating={ main.numbers.score_rev_growth }
              starRatedColor={scoreColor(main.numbers.score_rev_growth)}
              starDimension='12px'
              starSpacing='2px'
              numberOfStars={ 5 }
            /></div></li> : null}
            </ul>*/}

          </div>
          <div className='col-md-12'>
            {socialBlind( social.bl_url )}
            {socialInstagram( social.ig_url )}
            {socialGlassdoor( social.gd_url )}
            {socialFb( social.fb_url )}
            {socialTwitter( social.tr_url )}
            {socialLinkedin( social.ln_url )}
            {socialGithub( social.gh_url )}
          </div>
          <div class='col-md-12'>
            <a class='font-10 btn btn-round-sm btn-lightgray green mt-2' href={`https://www.stockideashq.com/stocks/${ticker.toLowerCase()}`} >Click here for more discussions</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Company;
