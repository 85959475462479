import { Component } from 'preact';
import style from './style.css';
import FaSearch from './../svg-components/fa-search';

const getSuggestions = ( arr, value ) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0 ? [] : arr.sort( ( a, b ) => ( a.value.toLowerCase() === value.toLowerCase() ? -10000 : a.value.length - b.value.length ) ).filter( d =>
    d.value.toLowerCase().slice( 0, inputLength ) === inputValue ||
    d.name.toLowerCase().indexOf( inputValue ) > -1 ).slice( 0, 15 );
};

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as tickersmapActions from './../../actions/tickersmap';

@connect(reduce, tickersmapActions)
export default class TickerBox extends Component {
  constructor( props ) {
    super( props );
    this.inputOnChange = this.inputOnChange.bind( this );
    this.onClick = this.onClick.bind( this );
    this.onSelect = this.onSelect.bind( this );
    this.onKeyDown = this.onKeyDown.bind( this );
    this.hide = this.hide.bind( this );
    this.state = {
      isSelecting: false,
      suggestions: [], 
      ticker: ''
    };
  }

  onClick() {
    this.props.getTickersMap();
  }

  inputOnChange(e) {
    const { tickersmap } = this.props;
    const value = e.target.value;
    global.hideTickerBox = this.hide;
    this.setState({ ticker: value, suggestions: getSuggestions( tickersmap, value ), isSelecting: true });
  }

  onSelect(ticker) {
    this.props.onSuggestionSelected({ suggestionValue: ticker } );
    this.setState({ isSelecting: false });
  }

  hide() {
    this.setState({ isSelecting: false });
  }

  onKeyDown( e ) {
    if ( e.key === 'Enter' ) {
      const str = e.target.value;
      const { suggestions } = this.state;
      let ticker;
      if ( !suggestions || !suggestions.length) {
        ticker = "AAPL";
      } else {
        const tickers = suggestions.map( d => d.value );
        if ( tickers.indexOf( str ) > -1 ) {
          ticker = suggestions[ tickers.indexOf( str ) ].value;
        } else {
          ticker = suggestions[ 0 ].value;
        }
      }
      this.props.onSuggestionSelected({ suggestionValue: ticker } );
      this.setState({ isSelecting: false });
    }
  }

  render() {
    const { suggestions, isSelecting } = this.state;
    const { value, tickersmap = [], zIndex = 10, theme = 'light', disabled } = this.props;
    const buttonClass = `${style['si-ticker-button']} btn theme-black-${theme} bg-white`;
    const inputClass = `${style['si-ticker-input']} form-control theme-black-${theme}`;

    return (
      <div style={ {
        position: 'relative', zIndex, height: 25,
      } }
      >
        <div style={ { width: '100%', overflow: 'visible', position: 'absolute', marginTop: 2 } }>
          <div class="input-group">
            <div class="input-group-prepend">
              <button onClick={this.onSelect.bind(this, this.state.ticker)} class={buttonClass}><FaSearch class='font-12' /></button>
            </div>
            <input 
              type="text" 
              defaultValue={value}
              class={inputClass} 
              onClick={this.onClick}
              onChange={this.inputOnChange}
              onKeyDown={this.onKeyDown}
              disabled={disabled}
              placeholder={'Search for a company'} 
            />
          </div>
          {isSelecting ? <div class={`${style['si-box-text']} shadow`}>
            {suggestions.map((suggestion, i) => <div key={i} class={`${style['si-box-text-inner']}`} onClick={this.onSelect.bind(this, suggestion.value)}>
              <span class='darkred bold'>{suggestion.value}</span><span class='ml-2'>{suggestion.name.length < 25 ? suggestion.name : `${ suggestion.name.slice( 0, 25 ) }...`}</span>
            </div>)}
          </div> : null}
        </div>
      </div>
    );
  }
}
