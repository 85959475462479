import { toast } from 'react-toastify';
import APIUtils from './../../utils/APIUtils';

export const checkEmail = ( email ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/email/exists', { email } );

export const login = ( email, password ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/login', { email, password } ).then( d => {
  return dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } );
} ).catch( err => {
  if ( err.message === 'error_user_not_verified' ) {
    toast.error( '⚠️ Your email is not verified yet, so sad.' );
    return;
  }
  toast.error( err.message || 'Error logging-in.' );
  console.log( err );
} );

export const loginGoogle = ( accessToken ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/login-google', { accessToken } ).then( d => {
  return dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } );
} ).catch( err => {
  if ( err.message === 'error_user_not_verified' ) {
    toast.error( '⚠️ Your email is not verified yet, so sad.' );
    return;
  }
  toast.error( err.message || 'Error logging-in.' );
  console.log( err );
} );

export const loginGoogleJwt = ( credential ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/login-google-jwt', { credential } ).then( d => {
  return dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } );
} ).catch( err => {
  if ( err.message === 'error_user_not_verified' ) {
    toast.error( '⚠️ Your email is not verified yet, so sad.' );
    return;
  }
  toast.error( err.message || 'Error logging-in.' );
  console.log( err );
} );

export const resetPasswordActual = (resetcode, password) => (dispatch, getState) => {
  APIUtils.post(getState().token, '/auth/reset-password', { resetcode, password }).then((info) => {
    dispatch({
      type: 'USER_TOKEN_GET',
      payload: { user: info.user, token: info.token },
    });
  }).catch(err => {
    toast.error(err.message ||  'Error resetting password user.');
    return;
  })
};

export const fetchProfile = ( ) => ( dispatch, getState ) => APIUtils.get( getState().token, '/auth/loggedin' ).then( user => dispatch( {
  type: 'USER_TOKEN_GET',
  payload: { user, token: getState().token },
} ) ).catch( err => {
  console.log( err );
} );

export const registerGoogle = ( body ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/register-google', body ).then( d =>
  dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } ) ).catch( err => {
  toast.error( err.message || 'Error registering an account.' );
  console.log( err );
} );

export const registerGoogleJwt = ( body ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/register-google-jwt', body ).then( d =>
  dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } ) ).catch( err => {
  toast.error( err.message || 'Error registering an account.' );
  console.log( err );
} );

export const register = ( body ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/register', body ).then( d => {
  dispatch( {
    type: 'USER_TOKEN_GET',
    payload: d,
  } );
  window.location = '/thankyou';
} ).catch( err => {
  toast.error( err.message || 'Error registering an account.' );
  console.log( err );
} );

export const resetPassword = ( email ) => ( dispatch, getState ) => APIUtils.post( getState().token, '/auth/request-reset', { email } ).then( d => {
  window.location = '/thankyou-reset';
  return dispatch( {
    type: 'GET_LATEST_PATTERNS',
    payload: d,
  } );
} ).catch( err => {
  toast.error( err.message || 'Error resetting password user.' );
  console.log( err );
} );

export const logout = () => ( {
  type: 'USER_TOKEN_REMOVE',
} );
