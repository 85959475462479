import { Component } from 'preact';

import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as websocketActions from './../../actions/websocket';

@connect(reduce, websocketActions)
export default class PhoenixChannel extends Component {
  componentDidMount() {
    this.props.initializeSocket();
    this.props.subscribeChannel( 'room:lobby', this.props.token );
    // this.props.subscribeChannel( 'room:chat', this.props.token );
  }
  render() {
    return null;
  }
}
