import { Socket, Presence } from 'phoenix';
import APIUtils from './../../utils/APIUtils';
const wsUrl = process.env.PREACT_APP_WS_URL;

export const initializeSocket = () => ( dispatch ) => {
  const socket = new Socket( `${ wsUrl }socket/websocket?vsn=1.0.0` );
  socket.connect();

  dispatch( {
    type: 'SOCKET_SET',
    payload: socket,
  } );
};

// const cleanThem = ( data ) => data.timestamps.map( ( d ) => {
//   const date = new Date( d * 1000 );
//   return {...data, date}
// } );

// const patchTo4pm = ( raw ) => {
//   if ( !raw ) {
//     return;
//   }
//   if ( raw.length < 2 ) {
//     return;
//   }
//   // TODO temporarily disable this thing
//   return raw;
// };

// const nineAmToFourPm = ( d ) => patchTo4pm( cleanThem( d ) );

export const subscribeChannel = ( topic, token ) => ( dispatch, getState ) => {
  const socket = getState().socket;
  const channel = socket.channel( topic, token );

  dispatch( {
    type: 'CHANNEL_SET',
    payload: channel,
  } );

  let presence = new Presence(channel);

  function renderOnlineUsers(presence) {
    dispatch({
      type: 'GET_PRESENCE',
      payload: presence
    });
  }

  presence.onSync(() => renderOnlineUsers(presence));

  channel
    .join()
    .receive( 'ok', resp => {
      console.log( 'ok...' );
      const ticker = getState().ticker;
      dispatch( {
          type: 'SUBSCRIBE_TOPICS',
          payload: { chat: 'stockideashq' },
        } );
      if (ticker) {
        dispatch( {
          type: 'SUBSCRIBE_TOPICS',
          payload: { chart: ticker },
        } );
      }
    } )
    .receive( 'error', resp => {
      console.log(resp); // eslint-disable-line
    } );
  channel.on( 'message', resp => {
    if ( resp.type === 'token' ) {
      if ( resp.msg.token ) {
        dispatch( {
          type: 'USER_TOKEN_GET',
          payload: { user: resp.msg.user, token: resp.msg.token },
        } );
        if ( resp.msg.user.theme ) {
          dispatch( {
            type: 'SET_THEME',
            payload: resp.msg.user.theme,
          } );
        }
      }
    }
    if ( resp.type === 'chat_message' ) {
      dispatch( {
          type: 'TELEGRAM_SET_MESSAGES',
          payload: {messages: [resp.msg], hasNewMessages: true},
        } );
    }
    if ( resp.type === 'quotes' ) {
      if ( resp.msg ) {
        dispatch( {
          type: 'GET_QUOTES',
          payload: resp.msg,
        } );
      }
    }
    if ( resp.type === 'charts' ) {
      dispatch( {
        type: 'GET_TICKERS_CHARTS',
        payload: resp.msg,
      } );
    }
    if ( resp.type === 'chart' ) {
      if ( resp.msg.info ) {
        resp.msg.info.date = new Date( resp.msg.info.dateshort );
        resp.msg.info.date.setTime( resp.msg.info.date.getTime() + resp.msg.info.date.getTimezoneOffset() * 60 * 1000 );
        // patching timestamps to 4pm
        // resp.msg.info.data = nineAmToFourPm( resp.msg.info.data );
        // resp.msg.info.data = nineAmToFourPm( _.cloneDeep( resp.msg.info.data ) );
        dispatch( {
          type: 'GET_TICKER_CHART',
          // payload: _.cloneDeep( resp.msg.info ),
          payload: resp.msg.info,
        } );
      }
    }
  } );
  dispatch( {
    type: 'JOIN_CHANNEL',
    payload: { topic, channel },
  } );
};
