import { Component } from 'preact';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'preact-redux';
import reduce from '../reducers';
import * as authActions from './../actions/auth';
import ReactGA from 'react-ga';

import Header from './header';
import Footer from './footer';
import PhoenixChannel from './../components/phoenixchannel';
import PostLikeTracker from './../components/system/post-like-tracker';
import LazyLoad from 'react-lazyload';
// const Webchat  = loadable(() => import('./../components/webchat'));
import Router from './../router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import loadable from '@loadable/component';
const OneTapLoginOrRegister  = loadable(() => import('./../pages/login/onetap'));

ReactGA.initialize(process.env.PREACT_APP_GA_TRACKING_ID);

@connect(reduce, authActions)
export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowOneTap: false,
			isShowChat: false
		}
	}

	componentWillMount() {
        setTimeout(() => {
            this.setState({ isShowOneTap : true });
        }, 15000);
        setTimeout(() => {
            this.setState({ isShowChat : true });
        }, process.env.PREACT_APP_SHOW_WEBCHAT_TIMEOUT || 30000);
    }

	render() {
		const user = this.props.user;
		const { isShowOneTap, isShowChat } = this.state;

		return (
			<div>
				<Header />
				<div class='header-margin' />
				{(!user || !user._id) && isShowOneTap ? <OneTapLoginOrRegister /> : null}
				{user && user._id ? <PhoenixChannel /> : <PhoenixChannel />}
				{user && user._id ? <PostLikeTracker /> : null}
				<Router />
				<Footer />
				{/*{!process.env.PREACT_APP_MOBILE_BUILD && ((user && user._id) || isShowChat) ? <Webchat /> : null}*/}
				<ToastContainer />
				<CookieConsent style={{ zIndex: 999999 }}>This website uses cookies to enhance the user experience.</CookieConsent>
			</div>
		);
	}
}
