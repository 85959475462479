import { Fragment } from 'preact';
import { Provider } from 'preact-redux';
import dayjs from 'dayjs';
import store from './store';
import App from './components/app';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import './style';

dayjs.extend( weekOfYear );
dayjs.extend( dayjsPluginUTC );

export default () => (
  <Provider store={store}>
    <App />
    <Fragment />
  </Provider>
);
