import dayjs from 'dayjs';
import _ from './lodash';

const calculateCurrent = ( current, {
  open, high, low, close, volume, dateshort,
} ) => {
  current.dateshort = current.dateshort || dateshort;
  current.date = new Date( current.dateshort );
  current.open = current.open || open;
  current.close = close || current.close;
  current.high = Math.max( current.high || 0, high );
  current.low = Math.min( current.low || 999999999999, low );
  current.volume = ( current.volume || 0 ) + volume;
  return current;
};

const formatMarketCap = ( num ) => {
  if ( num < 0 ) {
    return `-${ formatMarketCap( -num ) }`;
  }
  if ( num > 1000000000000 ) {
    return `${ ( num / 1000000000000 ).toFixed( 2 ) }T`;
  }
  if ( num > 1000000000 ) {
    return `${ ( num / 1000000000 ).toFixed( 2 ) }B`;
  }
  if ( num > 1000000 ) {
    return `${ ( num / 1000000 ).toFixed( 2 ) }M`;
  }
  return num;
};

const financials = (number) => {
  if (!number) return;
  if (isNaN(number)) return;
  return Number(number).toLocaleString();
};

const isNextWeek = ( currentDateStr, prevDateStr ) => dayjs( currentDateStr ).day() <= dayjs( prevDateStr ).day();

const isNextMonth = ( currentDateStr, prevDateStr ) => {
  const m1 = dayjs( currentDateStr ).month();
  const m2 = dayjs( prevDateStr ).month();
  return m1 > m2 || ( m1 === 0 && m2 === 11 );
};

const calculatePriceAndPatterns = ( price, patterns ) => ( { priceAndPatterns: price, patterns } );

const reduceByWeek = ( data ) => {
  const arr = [];
  let current = {};
  let prevDateStr = ( _.last( data ) || {} ).dateshort;
  for ( let i = 0; i < data.length; i++ ) {
    const currentDateStr = data[ i ].dateshort;
    if ( current.open && isNextWeek( currentDateStr, prevDateStr ) ) {
      arr.push( current );
      current = {};
    }
    prevDateStr = currentDateStr;
    calculateCurrent( current, data[ i ] );
  }
  if ( current.open ) {
    arr.push( current );
  }
  return arr;
};

const reduceByMonth = ( data ) => {
  const arr = [];
  let current = {};
  let prevDateStr = ( _.last( data ) || {} ).dateshort;
  for ( let i = 0; i < data.length; i++ ) {
    const currentDateStr = data[ i ].dateshort;
    if ( current.open && isNextMonth( currentDateStr, prevDateStr ) ) {
      arr.push( current );
      current = {};
    }
    prevDateStr = currentDateStr;
    calculateCurrent( current, data[ i ] );
  }
  if ( current.open ) {
    arr.push( current );
  }
  return arr;
};

const qyScore = (str) => {
  const year = parseInt(str.slice(0, 4), 10)
  const month = parseInt(str.slice(5, 7), 10)
  return year * 4 + Math.floor((month - 1) / 3)
}

const isPrevQ = (qPrev, qCurrent) => {
  if (!qCurrent) return true;
  return qyScore(qPrev) === qyScore(qCurrent) - 1;
};

const removeGapsFromTheEnd = (arr) => {
  let currentReportDate;
  const res = []
  for (var i = arr.length - 1; i >= 0; i--) {
    const reportDate = arr[i].reportDate;
    if (isPrevQ(reportDate, currentReportDate)) {
      res.unshift(arr[i])
    } else {
      
    }
    currentReportDate = reportDate;
  }
  return res;
}

const expandScreener = (info) => {
  const meta = info.meta.concat( [
    'p_today',
    'p_1_r',
    'p_2_r',
    'p_5_r',
    'p_10_r',
    'p_20_r',
    'p_60_r',
    'p_120_r',
    'p_250_r',
    'p_2500_r',
    'turnover',
    'amount',
    // 'psRatioDivideByGrowth',
    // 'psRatioDivideByGrowth2',
    'p_ytd_r',
  ] );
  const priceIdx = meta.indexOf( 'price' );
  const prevIdx = meta.indexOf( 'prev' );
  const p_1Idx = meta.indexOf( 'p_1' );
  const p_2Idx = meta.indexOf( 'p_2' );
  const p_5Idx = meta.indexOf( 'p_5' );
  const p_10Idx = meta.indexOf( 'p_10' );
  const p_20Idx = meta.indexOf( 'p_20' );
  const p_60Idx = meta.indexOf( 'p_60' );
  const p_120Idx = meta.indexOf( 'p_120' );
  const p_250Idx = meta.indexOf( 'p_250' );
  const p_2500Idx = meta.indexOf( 'p_2500' );
  const sharesOutstandingIdx = meta.indexOf( 'sharesOutstanding' );
  const volumeIdx = meta.indexOf( 'volume' );
  const priceToSalesIdx = meta.indexOf( 'priceToSales' );
  const revenueGrowthYoYIdx = meta.indexOf( 'revenueGrowthYoY' );
  const envOfPrevYearPriceIdx = meta.indexOf( 'end_of_prev_year_price' );

  const data = info.data.map( d => d.concat( [
    d[ prevIdx ] ? d[ priceIdx ] / d[ prevIdx ] : null,
    d[ p_1Idx ] ? d[ priceIdx ] / d[ p_1Idx ] : null,
    d[ p_2Idx ] ? d[ priceIdx ] / d[ p_2Idx ] : null,
    d[ p_5Idx ] ? d[ priceIdx ] / d[ p_5Idx ] : null,
    d[ p_10Idx ] ? d[ priceIdx ] / d[ p_10Idx ] : null,
    d[ p_20Idx ] ? d[ priceIdx ] / d[ p_20Idx ] : null,
    d[ p_60Idx ] ? d[ priceIdx ] / d[ p_60Idx ] : null,
    d[ p_120Idx ] ? d[ priceIdx ] / d[ p_120Idx ] : null,
    d[ p_250Idx ] ? d[ priceIdx ] / d[ p_250Idx ] : null,
    d[ p_2500Idx ] ? d[ priceIdx ] / d[ p_2500Idx ] : null,
    d[ volumeIdx ] && d[ sharesOutstandingIdx ] ? d[ volumeIdx ] / d[ sharesOutstandingIdx ] : null,
    d[ volumeIdx ] && d[ priceIdx ] ? Math.floor( d[ volumeIdx ] * d[ priceIdx ] / 1000000 ) : null,
    // d[ priceToSalesIdx ] && d[ revenueGrowthYoYIdx ] ? d[ priceToSalesIdx ] / d[ revenueGrowthYoYIdx ] : null,
    // d[ priceToSalesIdx ] && d[ revenueGrowthYoYIdx ] ? d[ priceToSalesIdx ] / d[ revenueGrowthYoYIdx ] / d[ revenueGrowthYoYIdx ] : null,
    d[ envOfPrevYearPriceIdx ] ? d[ priceIdx ] / d[ envOfPrevYearPriceIdx ] : null,
  ] ) );
  return { data, meta };
}

function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

export {
  expandScreener,
  financials,
  formatMarketCap,
  calculatePriceAndPatterns,
  removeGapsFromTheEnd,
  reduceByWeek,
  reduceByMonth,
  isAlphaNumeric,
}
