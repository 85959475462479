import { Component } from 'preact';
import style from './style.css';

export default class GradientHide extends Component {
  constructor(props) {
    super(props);
    this.expand = this.expand.bind(this);
    this.state = {
      isExpand: false,
    }
  }

  expand() {
    this.setState({isExpand: true})
  }

  render() {
    const { height, theme = 'light' } = this.props;
    const { isExpand } = this.state;

    return (
      <div class={isExpand ? '' : `${style['si-gradient-overlay']} ${style['si-gradient-overlay-' + theme]}`} style={{ height: isExpand ? '' : height }} onClick={this.props.fn || this.expand}>
        {this.props.children}
      </div>
    );
  }
}
