import _ from './../../utils/lodash';
import { Helmet } from 'react-helmet';
import { Component } from 'preact';
import { connect } from 'preact-redux';
import reduce from '../../reducers';
import * as miscActions from '../../actions/misc';

import loadable from '@loadable/component';
const Signals  = loadable(() => import('./../../components/signals'));

const title = 'Growth Company Signals';
const content = 'We monitor a list of growth companies with good prospects and notify you when the prices move away or toward important support levels.';
const image = 'https://i.imgur.com/v4BylB8.png';

@connect(reduce, miscActions)
export default class SignalsPage extends Component {
  componentDidMount = () => {
    this.props.getSignals(0);
    this.props.getSignals(1);
    this.props.getSignals(2);
    this.props.getSignals(10);
    this.props.getSignals(20);
    this.props.getSignals(60);
    this.props.getSignals(90);
  };

  render({ signals = [], user, theme = 'light' }, { text }) {
    const tickers = signals[0] && signals[0].signals.map(d => d.ticker)

    return (
      <div className={ `tags-panel-3 theme-${ theme } pb-3` }>
        <Helmet>
          <title>Stock Signals - Stockideas HQ</title>
          <meta property='og:title' content={ title } />
          <meta property='og:image' content={ image } />
          <meta name='description' content={ content } />
          <link rel='canonical' href='/sectors' />
        </Helmet>
        <div className='browser-hide p-1'>
          <span className='font-14 darkred bg-yellow'>For the best user experience please use a browser to proceed.</span>
        </div>
        <div className='row no-gutters'>
          <div className='col-md-12'>
            <h1 className={`font-30 theme-darkred-${theme}`}>Stock Signals</h1>
            <h2 className='font-25'>We create signals for growth companies whose price is moving away or moving toward key support levels. </h2>
            <div className='mb-1 font-14'>We define growth companies as companies that show growth some key areas. Some criteria we use to filter companies are as follows:</div>
            <ul className={`theme-green-${theme} font-14`}>
              <li>Their revenue growth is greater than 20% yoy.</li>
              <li>They have some sort of domination in their sector.</li>
              <li>They are growing in headcounts or have passed a certain threshold in terms of headcounts.</li>
              <li>Their R&D expense is greater than 5% of revenue.</li>
              <li>Their cashflow is improving... etc</li>
            </ul>
            <div>But when we backtest the results we found out that they're usually companies with higher beta and can be riskier. Please do your due diligence and use it at your own risk. 🚀🚀🚀</div>
            <hr className='mb-0' />
          </div>
          <Signals {...this.props} />
        </div>
      </div>
    );
  }
}
