import { Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './header.css';
import { connect } from 'preact-redux';
import { route } from 'preact-router';
import reduce from '../../reducers';
import * as authActions from './../../actions/auth';

@connect(reduce, authActions)
export default class LoginButton extends Component {
  render() {
    const { user } = this.props;
    if ( user && user._id ) {
      return (
        <Link activeClassName={style['si-active']} class={`mobile-hide green`} href="/me">{user.username}</Link>
      );
    } else {
      return (
        <Link activeClassName={style['si-active']} class={`mobile-hide green`} href="/login">Login</Link>
        // <div class='btn btn-round btn-warning' onClick={() => route('/login')}>Login</div>
      )
    }
  }
}
