import { toast } from 'react-toastify';
import APIUtils from './../../utils/APIUtils';

export const hidePost = ( id ) => ( dispatch, getState ) => {
  if ( !id ) {
    toast.info( '🎉 No post id somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/posts/${ id }/hide`, { } ).then( ( ) => {
    toast.info( '🎉 Successfully hide this post' );
  } ).catch( err => {
    toast.error( '⚠️ Error hide post' );
  } );
};

export const refreshMiscData = ( ticker, data ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/misc-data`, { data } ).then( ( info ) => {
    toast.info( '🎉 Recalculated misc data' );
  } ).catch( err => {

  } );
};

export const refreshCik = ( ticker, cik ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/cik`, { value: cik } ).then( ( info ) => {
    toast.info( '🎉 Recalculated cik' );
  } ).catch( err => {

  } );
};

export const setTwitterHandle = ( ticker, twitterHandle ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/twitter-handle`, { value: twitterHandle } ).then( ( info ) => {
    toast.info( '🎉 Recalculated twitter' );
  } ).catch( err => {

  } );
};

export const setLinkedinHandle = ( ticker, linkedinHandle ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/linkedin-handle`, { value: linkedinHandle } ).then( ( info ) => {
    toast.info( '🎉 Recalculated linkedin' );
  } ).catch( err => {

  } );
};

export const setBlindHandle = ( ticker, blindHandle ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/blind-handle`, { value: blindHandle } ).then( ( info ) => {
    toast.info( '🎉 Recalculated blind' );
  } ).catch( err => {

  } );
};

export const setGlassdoorHandle = ( ticker, glassdoorHandle ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/glassdoor-handle`, { value: glassdoorHandle } ).then( ( info ) => {
    toast.info( '🎉 Recalculated glassdoor' );
  } ).catch( err => {

  } );
};

export const setInstagramHandle = ( ticker, instagramHandle ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/instagram-handle`, { value: instagramHandle } ).then( ( info ) => {
    toast.info( '🎉 Recalculated instagram' );
  } ).catch( err => {

  } );
};

export const setMainGithubRepo = ( ticker, githubUsername, githubRepo ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/github-repo`, { githubUsername, githubRepo } ).then( ( info ) => {
    toast.info( '🎉 Recalculated main github repo' );
  } ).catch( err => {

  } );
};

export const refreshDescription = ( ticker, description ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/description`, { value: description } ).then( ( info ) => {
    toast.info( '🎉 Recalculated description' );
  } ).catch( err => {

  } );
};

export const refreshName = ( ticker, name ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/name`, { name } ).then( ( info ) => {
    toast.info( '🎉 Recalculated name' );
  } ).catch( err => {

  } );
};

export const refreshUrl = ( ticker, url ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/url`, { url } ).then( ( info ) => {
    toast.info( '🎉 Recalculated url' );
  } ).catch( err => {

  } );
};

export const editFounded = ( ticker, founded ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.put( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/founded`, { founded } ).then( ( info ) => {
    toast.info( '🎉 Recalculated founded date' );
  } ).catch( err => {

  } );
};

export const refreshImage = ( ticker, url ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/img`, { url } ).then( ( info ) => {
    toast.info( '🎉 Recalculated image url' );
  } ).catch( err => {

  } );
};

export const refreshLogo = ( ticker, url ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/logo`, { url } ).then( ( info ) => {
    toast.info( '🎉 Recalculated logo url' );
  } ).catch( err => {

  } );
};

export const refreshMeta = ( ticker, meta ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/meta`, { meta: JSON.parse( meta ) } ).then( ( info ) => {
    toast.info( '🎉 Recalculated meta' );
  } ).catch( err => {

  } );
};

export const refreshEdgar = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/edgar`, { } ).then( ( info ) => {
    toast.info( '🎉 Recalculated edgar' );
  } ).catch( err => {

  } );
};

export const addTag = ( tag, ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  if ( !tag ) {
    toast.info( '🎉 No tag somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/tags`, { tag } ).then( ( info ) => {
    toast.info( '🎉 Successfully added a tag' );
  } ).catch( err => {
    toast.error( '⚠️ Error creating tags' );
  } );
};

export const addPrettyTag = ( tag, ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  if ( !tag ) {
    toast.info( '🎉 No tag somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/pretty-tags`, { tag } ).then( ( info ) => {
    toast.info( '🎉 Successfully added a tag' );
  } ).catch( err => {
    toast.error( '⚠️ Error creating tags' );
  } );
};

export const addIpoInfo = ( ticker, info ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  if ( !info ) {
    toast.info( '🎉 No info somehow' );
    return;
  }
  APIUtils.put( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/ipo`, info ).then( ( info ) => {
    toast.info( '🎉 Successfully added IPO info' );
  } ).catch( err => {
    toast.error( '⚠️ Error adding IPO info' );
  } );
};

export const addTicker = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, '/admin/tickers', { ticker } ).then( ( info ) => {
    toast.info( '🎉 Successfully added a ticker' );
  } ).catch( err => {
    if ( err.meessage === 'error_quote' ) {
      toast.error( '⚠️ Error getting ticker quotes' );
      return;
    }
    toast.error( '⚠️ Error creating ticker' );
  } );
};

export const deleteTicker = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.del( getState().token, `/admin/tickers/${ ticker.toLowerCase() }` ).then( ( info ) => {
    toast.info( '🎉 Successfully deleted a ticker' );
  } ).catch( err => {
    toast.error( '⚠️ Error deleting ticker' );
  } );
};

export const refreshPrice = ( ticker ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/price`, { } ).then( ( info ) => {
    toast.info( '🎉 Recalculated Price' );
  } ).catch( err => {
    toast.error( '⚠️ Error refreshing price.' );
  } );
};

export const refreshKeywords = ( ticker, keywords ) => ( dispatch, getState ) => {
  if ( !ticker ) {
    toast.info( '🎉 No ticker somehow' );
    return;
  }
  APIUtils.post( getState().token, `/admin/tickers/${ ticker.toLowerCase() }/keywords`, { keywords } ).then( ( info ) => {
    toast.info( '🎉 Recalculated keywords' );
  } ).catch( err => {

  } );
};

export const deletePost = ( id, reason ) => ( dispatch, getState ) => {
  if ( !id ) {
    toast.info( '🎉 No post id somehow' );
    return;
  }
  let url = `/admin/posts/${ id }`
  if (reason) {
    url += `?reason=${reason}`
  }
  APIUtils.del( getState().token, url ).then( ( ) => {
    toast.info( '🎉 Successfully removed this post' );
  } ).catch( err => {
    toast.error( '⚠️ Error deleting post' );
  } );
};
