import { Component } from 'preact';
import { Link } from 'preact-router/match';
import _ from './../../utils/lodash';
import GradientHide from './../gradienthide';

const financials = (number) => {
  if (!number) return;
  return Number(number).toLocaleString();
};

export class Roster extends Component {
  render() {
    const { data = [], theme, ticker, isLoggedin } = this.props;
    if (!ticker) {
      return (
        <div>Not available at this time... </div>
      );
    }
    if (!data || !data.length) {
      return (
        <div>Not available at this time... </div>
      );
    }

    return (
      <div className='row m-1 row no-gutters'>
        <div className='col-md-12'>
        <GradientHide height={350}>
        <table className='table table-sm table-hover font-10'>
          <thead>
            <tr>
              <th>Insider</th>
              <th>Position</th>
              <th>Reported Date</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(_.filter(data, d => d.person && d.latestFilingShares), d => -d.latestFilingShares).slice(0, isLoggedin ? 99999 : 5).map(({person, personKey, latestFilingShares, latestFilingDate, title}, i) => {
              return (
                <tr key={i}>
                  <td><Link className={`theme-green-${theme} ml-1`} href={`/stocks/${ticker.toLowerCase()}/insiders/${personKey}`}>{person} {title ? <span>({title})</span> : null}</Link></td>
                  <td>{financials(latestFilingShares)}</td>
                  <td>{latestFilingDate}</td>
                </tr>
              );
            })}
            <tr>
              <td>
                <Link class='btn btn-warning m-2' href={'/login'}>Login to unlock all the metrics</Link>
              </td>
            </tr>
          </tbody>
        </table>
        </GradientHide>
        </div>
      </div>
    );
  }
}

export default Roster;
