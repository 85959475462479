import { Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './writer-bubble.css';
import { financials } from './../../utils/helper';
import Medals from './../medals';

export default class ComapniesBubble extends Component {
  render() {
    const { company, size = 'large' } = this.props;
    const employee_str = size === 'large' ? 'Number of Employees:' : 'Employees:';

    return (
      <Link href={`/stocks/${(company.ticker || '').toLowerCase()}`}>
        <div class='p-1'>
          <div className={ style['si-companies-bubble'] + ' row no-gutters' }>
            <div class='col-md-3 col-sm-3 col-3'>
              <div className={ style['si-companies-frame-' + size] }>
                <img src={company.logo_url || company.img_url} className={ style['si-companies-bubble-img'] } />
              </div>
            </div>
            <div class='col-md-9 col-sm-9 col-9'>
              <div className={ 'si-vertical-center pt-3' }>
                <div class='inline'><span class='darkred font-15-vw'>{company.name}</span></div>
                {/*<div class='inline ml-3 font-12 black'><Medals medals={company.medals} /></div>*/}
              </div>
              <div class='pb-2 font-12-vw mt-1'>
                <div class='black mt-1'>Followers: <i>{company.num_followers}</i>, Posts: <i>{company.num_posts}</i></div>
                <div class='black mt-1'>Sector: <i>{company.sector}</i></div>
                <div class='black mt-1'>{employee_str} <i>{financials(company.num_employees)}</i></div>
                <div class='mt-2'>{(company.pretty_tags || []).map(d => <div class='btn btn-light font-10-vw mr-1'>{d}</div>)}</div>
              </div>
            </div>
          </div>  
        </div>  
      </Link>
    );
  }
};
